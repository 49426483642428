import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardProyects } from "./CardProyects";
import { proyectsActions, servicesActions, userActions } from "../../_actions";
import { Constantes } from "../../_helpers";
import { useToasts } from 'react-toast-notifications';
import no_results from "../../_helpers/svgs/no_resultados.svg";
import { Menu, Transition } from '@headlessui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { filter, forEach, takeRightWhile } from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function Proyectos(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const user = useSelector(state => state.authentication);
    const users = useSelector(state => state.users);
    const alert = useSelector(state => state.alert);
    const projects = useSelector(state => state.projects);
    const services = useSelector(state => state.services);
    const subServices = useSelector(state => state.subServices);
    const [projectsArray, setProjectsArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [input, setInput] = useState({
        categoria: '',
        subCategoria: '',
        estado: '',
        razon: '',
        ejecutivo: '',
        executiveName: ''
    })
    const [navigation, setNavigation] = useState({
        items: [
            { id: 1, name: 'Solicitudes de cotización', href: '#', current: true },
            { id: 2, name: 'En Trámite', href: '#', current: false },
            { id: 3, name: 'En Proceso', href: '#', current: false },
            { id: 4, name: 'Terminados', href: '#', current: false },
            { id: 5, name: 'Favoritos', href: '#', current: false }
        ]
    });
    const [filterUser, setFilterUser] = useState('');

    useEffect(() => {
        dispatch(userActions.getAll());
    },[])

    useEffect(() => {
        if (alert.message && alert.message !== "") {
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    function loads() {
        if (!loaded && !projects.loading) {
            if (projects.items.length > 0) {
                if (input.categoria !== '' || input.subCategoria !== '' || input.estado !== ''
                    || input.razon !== '' || input.ejecutivo !== '') {
                    var arrayAux = [];
                    var filters = {}
                    if(input.categoria!=='')filters['serviceId']=parseInt(input.categoria);
                    if(input.subCategoria!=='')filters['subserviceId']=parseInt(input.subCategoria);
                    if(input.ejecutivo!=='')filters['assignedTo']=parseInt(input.ejecutivo);
                    var resp = filter(projects.items, filters);
                    if(resp.length > 0 && input.razon!==''){
                        arrayAux = resp.filter((item) => item && item.client && item.client.profile && item.client.profile.clientFilter.toLowerCase().includes(input.razon.toLowerCase()));
                    }else{
                        arrayAux = resp;
                    }
                    setProjectsArray(arrayAux);
                } else {
                    setProjectsArray(projects.items);
                }
                setLoaded(true);
            } else {
                setProjectsArray([]);
                setLoaded(true);
            }
        }
    }
    loads();
    const onClickCard = (item) => {
        const { history } = props;
        switch (item.section) {
            case Constantes.ESTATUS_SOLICITUDES:
                history.push(`/informacion/${item.id}`);
                break;
            case Constantes.ESTATUS_NO_INICIADO:
                history.push(`/documentos/${item.id}`);
                break;
            case Constantes.ESTATUS_EN_PROCESO:
                history.push(`/pagos/${item.id}`);
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if(user.user.roleSlug === 'executive') {
            dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES, assignedTo: parseInt(user.user.id) }));
        } else {
            dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES }));
        }
        dispatch(servicesActions.services({ businessId: 1 }));
    }, [user]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            // var navigationAux = navigation;
            var navigationAux = navigation;
            var nav = null;

            for (var i = 0; i <= navigationAux.items.length - 1; i++) {
                if (navigationAux.items[i].current) {
                    nav = navigationAux.items[i];
                }
            }
            setNavigation({ ...navigation, 'items': navigationAux.items });

            if(user.user.roleSlug === 'executive') {
                switch (nav.id) {
                    case 1:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES, assignedTo: parseInt(user.user.id) }, true));
                        break;
                    case 2:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_NO_INICIADO, assignedTo: parseInt(user.user.id) }, true));
                        break;
                    case 3:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_EN_PROCESO, assignedTo: parseInt(user.user.id) }, true));
                        break;
                    case 4:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_TERMINADOS, assignedTo: parseInt(user.user.id) }, true));
                        break;
                    case 5:
                        dispatch(proyectsActions.getAll({ favorite: parseInt(user.user.id), assignedTo: parseInt(user.user.id) }, true));
                        break;
                    default:
                        dispatch(proyectsActions.limpiar());
                        break;
                }
            } else {
                switch (nav.id) {
                    case 1:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES }, true));
                        break;
                    case 2:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_NO_INICIADO }, true));
                        break;
                    case 3:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_EN_PROCESO }, true));
                        break;
                    case 4:
                        dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_TERMINADOS }, true));
                        break;
                    case 5:
                        dispatch(proyectsActions.getAll({ favorite: parseInt(user.user.id) }, true));
                        break;
                    default:
                        dispatch(proyectsActions.limpiar());
                        break;
                }
            }

            
            
            setLoaded(false);
        }, 10000);

        return () => clearInterval(interval);

    }, []);

    const filterUserArray = useMemo(() => {
        if(filterUser === '') return users && users.items;

        return users && !users.loading && users.items &&
            users.items.filter((item) => item.displayName.toLowerCase().includes(filterUser.toLowerCase()))
    }, [filterUser, users]);

    const changeNavigations = (target) => {
        const name = target.getAttribute('name');
        var navigationAux = navigation;
        var nav = null;
        for (var i = 0; i <= navigationAux.items.length - 1; i++) {
            navigationAux.items[i].current = navigationAux.items[i].name === name ? true : false;
            if (navigationAux.items[i].current) {
                nav = navigationAux.items[i];
            }
        }
        setNavigation({ ...navigation, 'items': navigationAux.items });
        if(user.user.roleSlug === 'executive') {
            switch (nav.id) {
                case 1:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES, assignedTo: parseInt(user.user.id) }));
                    break;
                case 2:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_NO_INICIADO, assignedTo: parseInt(user.user.id) }));
                    break;
                case 3:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_EN_PROCESO, assignedTo: parseInt(user.user.id) }));
                    break;
                case 4:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_TERMINADOS, assignedTo: parseInt(user.user.id) }));
                    break;
                case 5:
                    dispatch(proyectsActions.getAll({ favorite: parseInt(user.user.id), assignedTo: parseInt(user.user.id) }));
                    break;
                default:
                    dispatch(proyectsActions.limpiar());
                    break;
            }
        } else {
            switch (nav.id) {
                case 1:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_SOLICITUDES }));
                    break;
                case 2:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_NO_INICIADO }));
                    break;
                case 3:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_EN_PROCESO }));
                    break;
                case 4:
                    dispatch(proyectsActions.getAll({ section: Constantes.ESTATUS_TERMINADOS }));
                    break;
                case 5:
                    dispatch(proyectsActions.getAll({ favorite: parseInt(user.user.id) }));
                    break;
                default:
                    dispatch(proyectsActions.limpiar());
                    break;
            }
        }
        
        setLoaded(false);
    }
    const handleFavorite = (item) => {
        if (parseInt(user.user.id) === item.favorite) {
            dispatch(proyectsActions.removeFavorite(item.id));
        } else {
            dispatch(proyectsActions.addFavorite(parseInt(user.user.id), item.id));
        }
    }
    const handleChange = (target, id, name) => {
        if (target) {
            const { name, value } = target;
            setInput({ ...input, [name]: value });
            setLoaded(false);
        }
        if (id) {
            setInput({ ...input, ejecutivo: parseInt(id), executiveName: name });
            setLoaded(false);
        }
    }
    const handleChangeCatego = ({ target }) => {
        const { name, value } = target;
        dispatch(servicesActions.subservices({ serviceId: parseInt(value) }));
    }
    const _render_filter = () => {
        return (
            <div className="tab w-full mt-10">
                <div className=" w-9/12  py-2">
                    <div className="grid grid-cols-5 gap-4">
                        <div className="w-full flex border border-gray-300 rounded-lg bg-gray-100 ">
                            <select value={input.categoria} id="categoria" name="categoria"
                                onChange={(e) => {
                                    handleChange(e.target)
                                    handleChangeCatego(e);
                                }}
                                className={input.categoria === "" ? "w-full p-4 border-black bg-gray-100 focus:outline-none text-gray-400" : "w-full p-4 border-black bg-gray-100 focus:outline-none"}>
                                <option disabled value="">Categor&iacute;a</option>
                                {(!services.loading && services.items) &&
                                    services.items.map((item, index) => {
                                        return (<option value={item.id}>{item.title}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="w-full flex border border-gray-300 rounded-lg bg-gray-100 ">
                            <select value={input.subCategoria} id="subCategoria" name="subCategoria" onChange={(e) => handleChange(e.target)}
                                className={input.subCategoria === "" ? "w-full p-4 border-black bg-gray-100 focus:outline-none text-gray-400" : "w-full p-4 border-black bg-gray-100 focus:outline-none"}>
                                <option disabled value="">Sub-Categor&iacute;a</option>
                                {(!subServices.loading && subServices.items) &&
                                    subServices.items.map((item, index) => {
                                        return (<option value={item.id}>{item.title}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="w-full flex border border-gray-300 rounded-lg bg-gray-100 ">
                            <input
                                type='text'
                                className={input.razon === "" ? "w-full p-4 border-black bg-gray-100 focus:outline-none text-gray-400" : "w-full p-4 border-black bg-gray-100 focus:outline-none"}
                                id="razon"
                                name="razon"
                                placeholder="Razón social o Alias"
                                onChange={(e) => handleChange(e.target)}
                                value={input.razon}
                            />
                        </div>
                        {
                            user && user.user && (user.user.roleSlug === 'admin' || user.user.roleSlug === 'quo') && (
                                <div className="w-full flex border border-gray-300 rounded-lg bg-gray-100 ">
                                    <Menu as="div" className="relative z-20 inline-block text-left">
                                        <Menu.Button className="w-full">
                                            <input
                                                type='text'
                                                className={input.executiveName === "" ? "w-full cursor-pointer p-4 border-black bg-gray-100 focus:outline-none text-gray-400" : "w-full cursor-pointer p-4 border-black bg-gray-100 focus:outline-none"}
                                                id="ejecutivo"
                                                name="ejecutivo"
                                                disabled
                                                placeholder="Ejecutivo de cuenta"
                                                value={input.executiveName}
                                            />
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute z-50 w-56 mt-2 origin-top-right bg-gray-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <input
                                                        type='text'
                                                        className={filterUser === "" ? "w-full p-4 border-black bg-gray-100 focus:outline-none text-gray-400" : "w-full p-4 border-black bg-gray-100 focus:outline-none"}
                                                        id="ejecutivo"
                                                        name="ejecutivo"
                                                        placeholder="Buscar..."
                                                        onChange={(e) => setFilterUser(e.target.value)}
                                                        value={filterUser}
                                                    />
                                                </div>
                                                <div className="py-1">
                                                    {
                                                        users && users.items && filterUserArray &&
                                                        filterUserArray.map((item, index) => {
                                                            if (item.roleSlug === 'executive') {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                type='button'
                                                                                onClick={(e) => {
                                                                                    handleChange(e, item.id, item.displayName);
                                                                                    setFilterUser('');
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'flex flex-col px-4 py-2 text-sm cursor-default hover:bg-gray-300 cursor-pointer w-full'
                                                                                )}
                                                                            >
                                                                                { item.displayName }
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {
                                                        users && users.items && filterUserArray &&
                                                        filterUserArray.length === 0 && (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        type='button'
                                                                        disabled
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'flex flex-col px-4 py-2 text-sm cursor-default w-full'
                                                                        )}
                                                                    >
                                                                        No se ha encontrado ejecutivos
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            )
                        }
                        <div>
                            <input type="button" value="Borrar filtros"
                            className={(input.categoria!==''||input.razon!==''||input.subCategoria!==''||input.ejecutivo!==''||input.executiveName !== '')?"p-4 border rounded-lg shadow cursor-pointer bg-color-btn text-white hover:shadow-none":"p-4 border rounded-lg text-gray-300"}
                            onClick={()=>{
                                setFilterUser('')
                                setInput({...input, 'categoria':'', 'razon':'', 'subCategoria':'', 'ejecutivo': '','executiveName': '' });
                                setLoaded(false);
                            }}
                            disabled={(input.categoria!==''||input.razon!==''||input.ejecutivo!==''||input.subCategoria!==''||input.executiveName !== '')?false:true}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const _render = () => {
        if (projects.loading) {
            return (
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (projectsArray.length > 0) {
                return (
                    <div className="grid grid-cols-3 gap-4 mt-12 w-full">
                        {projectsArray.map((item, index) => {
                            return <CardProyects item={item} userId={parseInt(user.user.id)} key={"card_-" + index} onClickCard={onClickCard} handleFavorite={handleFavorite} />
                        })}
                    </div>
                )
            } else {
                return (
                    <div className="w-full" align="center">
                        <img
                            src={no_results}
                            alt="icon" />
                    </div>
                )
            }
        }
    }
    return (
        <>
            <div className="min-h-screen flex justify-start bg-white py-12 sm:px-6 lg:px-8">
                <div className="w-full px-28">
                    <div align="left">
                        <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                            Proyectos
                        </h2>
                    </div>
                    <div className="container pt-10" align="left">
                        <div className="relative flex">
                            <div className="container">
                                <div className="sm:block ">
                                    <div className="w-full flex space-x-12">
                                        {navigation.items.map((item) => (
                                            <h5
                                                key={item.name}
                                                name={item.name}
                                                className={classNames(
                                                    item.current ? 'cursor-pointer border-b-4 border-color-select rounded-none' : 'cursor-pointer text-gray-500',
                                                    'px-3 pt-2 rounded-md text-sm font-semibold'
                                                )}
                                                onClick={(e) => changeNavigations(e.target)}>
                                                {item.name}
                                            </h5>
                                        ))}
                                    </div>
                                    {_render_filter()}
                                    {_render()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { Proyectos };