import { paymentsConstants } from '../_constants';
import { paymentsService, filesService } from '../_service';
import { alertActions } from "./alerts.actions";

export const paymentsActions = {
    create,
    update,
    deletePayment,
    getAll,
    get,
    createWithFiles,
    editWithFiles
};
function createWithFiles(dataRequest) {
    return dispatch => {
        dispatch(request());
        if (dataRequest.xml !== null && dataRequest.pdf !== null) {
            filesService.uploadFile(dataRequest.xml).then(
                responseXml => {
                    if (responseXml !== "") {
                        filesService.uploadFile(dataRequest.pdf).then(
                            responsePdf => {
                                if (responsePdf !== "") {
                                    let dataRequestAux = {
                                        projectid: dataRequest.projectid,
                                        clientid: dataRequest.clientid,
                                        duedate: dataRequest.duedate,
                                        amount: dataRequest.amount,
                                        xmlfileurl: responseXml,
                                        pdffileurl: responsePdf
                                    };
                                    paymentsService.create(dataRequestAux).then(
                                        payment => {
                                            dispatch(alertActions.show('success', "Pago registrado correctamente."));
                                            dispatch(success(payment));
                                        },
                                        error => {
                                            dispatch(failure(error.toString()));
                                            dispatch(alertActions.show('error', error.toString()));
                                        }
                                    );
                                }
                            },
                            error => {
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.show('error', error.toString()));
                            }
                        );
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.show('error', error.toString()));
                }
            );
        } else {
            dispatch(alertActions.show('error', "Se debe agregar XML y PDF"));
        }
    };
    function request() { return { type: paymentsConstants.CREATE_SUCCESS } }
    function success(payment) { return { type: paymentsConstants.CREATE_SUCCESS, payment } }
    function failure(error) { return { type: paymentsConstants.CREATE_FAILURE, error } }
}
function editWithFiles(dataRequest, id) {
    return dispatch => {
        dispatch(request());
        if (dataRequest.xml !== null && dataRequest.pdf !== null) {
            filesService.uploadFile(dataRequest.xml).then(
                responseXml => {
                    if (responseXml !== "") {
                        filesService.uploadFile(dataRequest.pdf).then(
                            responsePdf => {
                                if (responsePdf !== "") {
                                    let dataRequestAux = {
                                        projectid: dataRequest.projectid,
                                        clientid: dataRequest.clientid,
                                        duedate: dataRequest.duedate,
                                        amount: dataRequest.amount,
                                        xmlfileurl: responseXml,
                                        pdffileurl: responsePdf
                                    };
                                    paymentsService.update(dataRequestAux, id).then(
                                        payment => {
                                            dispatch(alertActions.show('success', "Pago se actualizo correctamente."));
                                            dispatch(success(payment));
                                        },
                                        error => {
                                            dispatch(failure(error.toString()));
                                            dispatch(alertActions.show('error', error.toString()));
                                        }
                                    );
                                }
                            },
                            error => {
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.show('error', error.toString()));
                            }
                        );
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.show('error', error.toString()));
                }
            );
        } else {
            dispatch(alertActions.show('error', "Se debe agregar XML y PDF"));
        }
    };
    function request() { return { type: paymentsConstants.UPDATE_REQUEST } }
    function success(payment) { return { type: paymentsConstants.UPDATE_SUCCESS, payment } }
    function failure(error) { return { type: paymentsConstants.UPDATE_FAILURE, error } }
}
function create(dataRequest) {
    return dispatch => {
        dispatch(request());
        paymentsService.create(dataRequest).then(
            payment => {
                dispatch(alertActions.show('success', "Pago registrado correctamente."));
                dispatch(success(payment));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request() { return { type: paymentsConstants.CREATE_SUCCESS } }
    function success(payment) { return { type: paymentsConstants.CREATE_SUCCESS, payment } }
    function failure(error) { return { type: paymentsConstants.CREATE_FAILURE, error } }
}
function update(dataRequest, id) {
    return dispatch => {
        dispatch(request());
        paymentsService.update(dataRequest, id).then(
            payment => {
                dispatch(success(payment));
                dispatch(alertActions.show('success', "Pago actualizado correctamente."));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));

            }
        );
    };
    function request() { return { type: paymentsConstants.UPDATE_REQUEST } }
    function success(payment) { return { type: paymentsConstants.UPDATE_SUCCESS, payment } }
    function failure(error) { return { type: paymentsConstants.UPDATE_FAILURE, error } }
}
function deletePayment(id) {
    return dispatch => {
        dispatch(request());
        paymentsService.deletePayment(id).then(
            payment => {
                dispatch(success(payment.id));
                dispatch(alertActions.show('success', "Pago eliminado correctamente."));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request() { return { type: paymentsConstants.DELETE_REQUEST } }
    function success(id) { return { type: paymentsConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: paymentsConstants.DELETE_FAILURE, error } }
}
function getAll(dataRequest) {
    return dispatch => {
        dispatch(request());
        paymentsService.getAll(dataRequest).then(
            payments => {
                dispatch(success(payments));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: paymentsConstants.GETALL_REQUEST } }
    function success(payments) { return { type: paymentsConstants.GETALL_SUCCESS, payments } }
    function failure(error) { return { type: paymentsConstants.GETALL_FAILURE, error } }
}
function get(id) {
    return dispatch => {
        dispatch(request());
        paymentsService.get(id).then(
            payment => {
                dispatch(success(payment));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: paymentsConstants.GET_REQUEST } }
    function success(payment) { return { type: paymentsConstants.GET_SUCCESS, payment } }
    function failure(error) { return { type: paymentsConstants.GET_FAILURE, error } }
}