import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";

export const projectsService = {
    create,
    update,
    getAll,
    get,
    addFavorite,
    removeFavorite
};
const client = initializeApollo(null);
const dataResponse = `
    id
    title
    description
    status
    createdAt
    businessId
    business
    serviceId
    service
    subserviceId
    subservice
    billingKindClient
    billingKindTarget
    client{
        id
        name
        lastname
        displayName
        email
        avatar
        enabled
        verified
        roleId
        role
        roleSlug
        createdAt
        updatedAt
        lastConnectionAt
        profile{
            alias
            clientFilter
            celular
            rfc
            representanteLegal
            razonSocial
            domicilioFiscal
            domicilioSocial
            nombreCompleto
            edad
            curp
            actaConstitutivaFileUrl
            actaNacimientoFileUrl
            ifeRepLegalFileUrl
            ifePersonaFisicaFileUrl
            comprobanteDomicilioFileUrl
            cifFileUrl
            curpFileUrl
            documentStatus{
                actaConstitutivaStatus
                actaNacimientoStatus
                ifeRepLegalStatus
                ifePersonaFisicaStatus
                comprobanteDomicilioStatus
                cifStatus
                curpStatus
            }
        }
    }
    favorite
    quoteFileUrl
    contractFileUrl
    contractsignedfileurl
    section
    documentStatus{
        quote
        contract
        signedContract
        rejectionReasonQuote
        rejectionReasonSignedContract
        legalAreaApproval
        rejectionReasonLegalArea
    }
    startDate
    amountToOperate
    numWorkers
    hoursPerWorker
    fulfillment
    waitingForLegalArea
    initProjectValidation
    assignedTo
    assigned
`
function create(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            createProject(input:${parsedDataRequest}){
                ${dataResponse}
            }
        }`
    }).then(response => {
        return response.data.createProject;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function update(dataRequest, id) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            updateProject(id:${id}, input:${parsedDataRequest}){
                ${dataResponse}
            }
        }`
    }).then(response => {
        return response.data.updateProject;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function getAll(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = ((dataRequest) ? ("(filter:" + JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:') + ")") : "");
    // console.log("coso", `
    // {
    //     projects${parsedDataRequest}{
    //         ${dataResponse}
    //     }
    // }`)
    return client.watchQuery({
        query: gql`
        {
			projects${parsedDataRequest}{
				${dataResponse}
			}
        }`
        // ,
        // pollInterval: 1000
    })
    // .subscribe(subsObject
        // ({ data, loading}) => {
        // console.log('++++++++ RESULTS +++++++++', data)
        // }
        // );
}
// const subsObject = {
//     next(data) {
//       console.log("received data", data);
//     },
//     error(err) {
//       console.error("err", err);
//     },
//     complete() {
//       console.log("Finished");
//     }
//   };
function get(id) {
    client.cache.reset();
    return client.query({
        query: gql`
        {
			project(id:${id}){
				${dataResponse}
			}
		}`
    }).then(response => {
        return response.data.project;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function addFavorite(userId, projectId) {
    client.cache.reset();
    return client.mutate({
        mutation: gql`
        mutation{
            addFavorite(userId:${userId}, projectId:${projectId}){
                ${dataResponse}
            }
        }`
    }).then(response => {
        return response.data.addFavorite;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function removeFavorite(projectId) {
    client.cache.reset();
    return client.mutate({
        mutation: gql`
        mutation{
            removeFavorite(projectId:${projectId}){
                ${dataResponse}
            }
        }`
    }).then(response => {
        return response.data.removeFavorite;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}