import { servicesConstants } from '../_constants';
import { servicesService } from '../_service';

export const servicesActions = {
    services,
    service,
    subservices,
    subservice
};
function services(dataRequest) {
    return dispatch => {
        dispatch(request());
        servicesService.services(dataRequest).then(
            services => {
                dispatch(success(services));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: servicesConstants.GETALL_REQUEST } }
    function success(services) { return { type: servicesConstants.GETALL_SUCCESS, services } }
    function failure(error) { return { type: servicesConstants.GETALL_FAILURE, error } }
}
function service(dataRequest) {
    return dispatch => {
        dispatch(request());
        servicesService.service(dataRequest).then(
            service => {
                dispatch(success(service));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: servicesConstants.GET_REQUEST } }
    function success(service) { return { type: servicesConstants.GET_SUCCESS, service } }
    function failure(error) { return { type: servicesConstants.GET_FAILURE, error } }
}
function subservices(dataRequest) {
    return dispatch => {
        dispatch(request());
        servicesService.subservices(dataRequest).then(
            subServices => {
                dispatch(success(subServices));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: servicesConstants.GETALL_SUB_REQUEST } }
    function success(subServices) { return { type: servicesConstants.GETALL_SUB_SUCCESS, subServices } }
    function failure(error) { return { type: servicesConstants.GETALL_SUB_FAILURE, error } }
}
function subservice(dataRequest) {
    return dispatch => {
        dispatch(request());
        servicesService.subservice(dataRequest).then(
            subService => {
                dispatch(success(subService));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: servicesConstants.GET_SUB_REQUEST } }
    function success(subService) { return { type: servicesConstants.GET_SUB_SUCCESS, subService } }
    function failure(error) { return { type: servicesConstants.GET_SUB_FAILURE, error } }
}