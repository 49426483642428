import React, { useState, createRef, useRef, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from "react-number-format";
import { proyectsActions, paymentsActions, notificationsActions } from "../../_actions";
import { createPopper } from "@popperjs/core";
import { Dialog, Transition } from '@headlessui/react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { useToasts } from 'react-toast-notifications';
import MaskedInput from 'react-text-mask';
import xml from "../../_helpers/svgs/xml.png";
import pdf from "../../_helpers/svgs/archivo-pdf.png";
import { saveAs } from 'file-saver';
import Loader from 'react-loader-spinner';

function Pago(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const scrollModePluginInstance = scrollModePlugin();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { item, updateStateSubmit, id } = props;
    const cancelButtonRef = useRef(null);
    const fileXmlInput = useRef(null);
    const filePdfInput = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);
    const file = useSelector(state => state.files);
    const projects = useSelector(state => state.projects);
    const [cargado, setCargado] = useState(false);
    const [b64, setB64] = useState(null);
    const [open, setOpen] = useState(false);
    const [openE, setOpenE] = useState(false);
    const [dXml, setDXml] = useState(false);
    const [submit, setSubmit] = useState(false);
    const btnRef = createRef();
    const popoverRef = createRef();
    const [inputs, setInputs] = useState({
        fechaPago: item.duedate.split('T')[0].split("-").reverse().join("/"),
        monto: item.amount,
        pdf: null,
        xml: null
    });
    useEffect(() => {
        dispatch(proyectsActions.get(parseInt(id)));
      }, []);
    const handleChange = ({ name, value }) => {
        setInputs({ ...inputs, [name]: value });
    }
    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "bottom-start"
        });
        setPopoverShow(true);
    };
    const closePopover = () => {
        setPopoverShow(false);
    };
    const openModalE = () => {
        closePopover();
        setOpenE(true);
    };
    const closeModalE = () => {
        setInputs({ ...inputs, 'xml': null, 'pdf': null })
        setOpenE(false);
    };
    const openModal = () => {
        dispatch(proyectsActions.getDocument(item.pdffileurl));
        setCargado(false);
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
        setCargado(false);
        setB64(null);
    };
    const addfile = (files, name) => {
        setInputs({ ...inputs, [name]: files[0] })

    }
    const savePayment = () => {
        if (inputs.fechaPago !== "" && inputs.monto !== "") {
            updateStateSubmit();
            if (inputs.pdf !== null && inputs.xml !== null) {
                let dataRequest = {
                    duedate: inputs.fechaPago.split("/").reverse().join("-"),
                    amount: parseFloat(isNaN(inputs.monto) ? inputs.monto.replaceAll(",", "").replaceAll("$", "") : inputs.monto),
                    xml: inputs.xml,
                    pdf: inputs.pdf
                };
                dispatch(paymentsActions.editWithFiles(dataRequest, item.id));
            } else {
                let dataRequest = {
                    duedate: inputs.fechaPago.split("/").reverse().join("-"),
                    amount: parseFloat(isNaN(inputs.monto) ? inputs.monto.replaceAll(",", "").replaceAll("$", "") : inputs.monto),
                };
                dispatch(paymentsActions.update(dataRequest, item.id));
            }
            let dataRequest ={
                email: projects.item.client.email,
                title: projects.item.title?projects.item.title:'Actualización del proyecto',
                movement: 'Se modifico un pago.',
                redirect:`details/${projects.item.id}?redirect=true`,
                subject: `Moficación de pago en el proyecto #${item.projectId}`
            };
            dispatch(notificationsActions.sendNotificationClient(dataRequest));
            dataRequest.email ='';
            dataRequest.redirect =`documentos/${projects.item.id}`;
            dataRequest.subject = `Moficación de pago en el proyecto #${item.projectId}`;
            dispatch(notificationsActions.sendNotificationOperator(dataRequest, projects.item.favorite));
            closeModalE();
        } else {
            addToast("La fecha de pago y el monto son requeridos.", { appearance: 'error' });
        }
    }
    const deletePayment = () => {
        dispatch(paymentsActions.deletePayment(item.id));
    }
    const handleClickFile = (e) => {
        const { name } = e.target
        switch (name) {
            case 'xml':
                fileXmlInput.current.click()
                break;
            case 'pdf':
                filePdfInput.current.click()
                break;
        }
    }
    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr(`data:${file.item.type};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: file.item.type });
    };
    const downloadXml = () => {
        dispatch(proyectsActions.getDocument(item.xmlfileurl));
        setDXml(true);
    }
    const downloadAux = () => {
        if (dXml) {
            if (b64 === null && !cargado && !file.loading) {
                let fileA = convertBase64ToFile(file.item.body, "facturaXml", file.item.type);
                saveAs(fileA, "facturaXml");
                setSubmit(false);
                setDXml(false);
                setB64(null);
                setCargado(false);
            }
        }
    }
    const convertBase64ToFile = (base64String, fileName, mimeType) => {
        let bstr = atob(base64String);
        let n = bstr.length;
        let uint8Array = new Uint8Array(n);
        while (n--) {
            uint8Array[n] = bstr.charCodeAt(n);
        }
        let file = new File([uint8Array], fileName, { type: mimeType });
        return file;
    }
    downloadAux();
    const _renderModalEdit = () => {
        return (
            <Transition.Root show={openE} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={openE}
                    onClose={closeModalE}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                      </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" align="center">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900" align="center">
                                                Registrar un pago
                                </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm font-normal">
                                                    Introduce el monto exacto del pago, la fecha en la que fue realizada y sube la factura
                                  </p>
                                            </div>
                                            <div>
                                                <h6 className="font-semibold pt-2">Fecha de pago:</h6>
                                                <MaskedInput
                                                    mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                    placeholder={'dd/mm/aaaa'}
                                                    showMask={false}
                                                    className="w-full p-2 mt-2 border-gray-300 border rounded"
                                                    name="fechaPago"
                                                    id="fechaPago"
                                                    value={inputs.fechaPago} onChange={({ target }) => handleChange(target)}
                                                />
                                                <h6 className="font-semibold pt-2">Monto pagado:</h6>
                                                <NumberFormat className="w-full p-2 mt-2 border-gray-300 border rounded" name="monto" id="monto" value={inputs.monto} onChange={({ target }) => handleChange(target)} fixedDecimalScale={true} decimalScale={2} displayType={'input'} thousandSeparator={true} prefix={'$'} />
                                                <h6 className="font-semibold pt-2">Factura:</h6>
                                                <div className="grid grid-cols-2 pt-2 pb-2 cursor-pointer" align="center">
                                                    <div className="pr-2 has-tooltip" align="center">
                                                        <span className='tooltip rounded shadow-lg p-1 bg-gray-200' style={
                                                            inputs.pdf ? {} : { display: 'none' }
                                                        }>{inputs.pdf ? inputs.pdf.name : ""}</span>
                                                        <img src={pdf} alt="pdf" name="pdf" id="pdf" className="w-1/3 cursor-pointer" onClick={handleClickFile} />
                                                    </div>
                                                    <div className="has-tooltip" align="center">
                                                        <span className='tooltip rounded shadow-lg p-1 bg-gray-200' style={
                                                            inputs.xml ? {} : { display: 'none' }
                                                        }>{inputs.xml ? inputs.xml.name : ""}</span>
                                                        <img src={xml} alt="xml" name="xml" id="xml" className="w-1/3 cursor-pointer" onClick={handleClickFile} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 px-4 pb-3">
                                    <div className="w-full px-1">
                                        <button
                                            type="button"
                                            className="w-full text-color-btn border-color-btn border rounded-lg py-2 text-sm"
                                            onClick={() => closeModalE()}
                                            ref={cancelButtonRef}>
                                            Cancelar
                        </button>
                                    </div>
                                    <div className="w-full px-1">
                                        <button
                                            type="button"
                                            className="w-full text-white bg-color-btn border rounded-lg py-2 text-sm"
                                            onClick={savePayment}>
                                            Guardar
                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const _renderModal = () => {
        var items = [];
        if (file.loading) {
            items.push(
                <div className="animate-pulse flex space-x-4 mx-10 mb-8" key="skeleton_-1">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else if (!file.loading && file.item.body && file.item.body !== "") {
            if (b64 === null && !cargado && open) {
                const blob = base64toBlob(file.item.body);
                const url = URL.createObjectURL(blob);
                setCargado(true);
                setB64(url);
            } else if (b64 !== null) {
                items.push(
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <Viewer
                            fileUrl={b64}
                            plugins={[
                                defaultLayoutPluginInstance,
                                scrollModePluginInstance,
                            ]}
                            />
                    </div>
                )
            } else {
                items.push(
                    <div className="animate-pulse flex space-x-4 mx-10 mb-8" key="skeleton_-2">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-400 rounded w-full"></div>
                            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-400 rounded"></div>
                                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={closeModal}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                  </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-6xl w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" align="center">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900" align="center">
                                                {item.name}
                                            </Dialog.Title>
                                            <div>
                                                {items}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full px-4 pb-3">
                                    <div className="px-1" align="center">
                                        <button
                                            type="button"
                                            className="p-4 text-color-btn border-color-btn border rounded-lg py-2 text-sm"
                                            onClick={() => closeModal()}
                                            ref={cancelButtonRef}>
                                            Cerrar
                            </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const _renderPopOver = () => {
        return (
            <div
                className={
                    (popoverShow ? "" : "hidden ") +
                    "shadow-lg bg-white border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                }
                ref={popoverRef}>
                <div>
                    <div className="p-3">
                        <input type="button" value="Eliminar" onClick={deletePayment} className="p-1 bg-white border-color-btn text-sm text-color-btn border-2 rounded px-4 mr-2 cursor-pointer" />
                        <input type="button" value="Editar" onClick={openModalE} className="p-1 bg-color-btn text-white text-sm border-2 border-color-btn rounded px-6 mr-2 cursor-pointer" />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="my-8">
            <div className="grid  grid-cols-3">
                <div align="center">
                    <h6 className="font-light text-sm">{item.duedate.split('T')[0].split("-").reverse().join("/")}</h6>
                    <h4 className="font-semibold">
                        <NumberFormat fixedDecimalScale={true} decimalScale={2} value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </h4>
                </div>
                <div className="pt-4">
                    <h5 className="text-color-btn font-semibold text-sm cursor-pointer" onClick={() => {
                        if (item.pdffileurl !== null) {
                            openModal();
                        } else {
                            addToast("Pago sin factura.", { appearance: 'error' });
                        }
                    }}>Ver factura</h5>
                </div>
                <div>
                    <div className="grid  grid-cols-3 pt-4">
                        <div className="col-span-2" align="right">
                            {submit ? (
                                <div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#50afbd"
                                        height={65}
                                        width={50}
                                        timeout={0}
                                    />
                                </div>
                            ) :
                                (<h5 className="text-color-btn font-semibold text-sm cursor-pointer" onClick={() => {
                                    if (item.xmlfileurl !== null) {
                                        setSubmit(true);
                                        downloadXml();
                                    } else {
                                        addToast("Pago sin xml.", { appearance: 'error' });
                                    }
                                }}>Descargar Xml</h5>)
                            }
                        </div>
                        <div>
                            <div className="hover:bg-gray-300 rounded w-min w-max cursor-pointer" onClick={() => {
                                popoverShow ? closePopover() : openPopover();
                            }}
                                ref={btnRef}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {_renderPopOver()}
            {_renderModal()}
            {_renderModalEdit()}
            <input type="file" name="xml" id="xml" onChange={({ target: { files, name } }) => addfile(files, name)} ref={fileXmlInput} style={{ display: 'none' }} accept=".xml"></input>
            <input type="file" name="pdf" id="pdf" onChange={({ target: { files, name } }) => addfile(files, name)} ref={filePdfInput} style={{ display: 'none' }} accept=".pdf"></input>
        </div>
    )
}
export { Pago };