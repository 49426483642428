import { createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

// const loggerMiddleware = createLogger();
// const middlewares = [thunkMiddleware, createDebounce(), loggerMiddleware];
const middlewares = [thunkMiddleware, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
