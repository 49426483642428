import { combineReducers } from 'redux';
import { users } from "./user.reducer";
import { invite } from "./invite.reducer";
import { projects } from "./projects.reducer";
import { authentication } from "./authentication.reducer";
import { alert } from "./alerts.reducer";
import { recover } from "./recover.reducer";
import { files } from "./files.reducer";
import { payments } from "./payments.reducer";
import { services } from "./services.reducer";
import { subServices } from "./subServices.reducer";

const rootReducer = combineReducers({
    alert,
    users,
    invite,
    projects,
    authentication,
    recover,
    files,
    payments,
    services,
    subServices
});

export default rootReducer;