import { useMemo } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import fetch from 'node-fetch';
import { createUploadLink } from "apollo-upload-client";
require('dotenv').config({ path: require('find-config')('.env') })

let apolloClient;

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GQL_URI,
    fetch,
    credentials: 'same-origin'
});

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return {
            headers: {
                ...headers,
            },
        };
    } else {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        };
    }
});

function createApolloClient() {
    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        connectToDevTools: true,
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    })
}

export function initializeApollo(initialState = null) {
    const _apolloClient = apolloClient ?? createApolloClient()

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract()
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        _apolloClient.cache.restore({ ...existingCache, ...initialState })
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
}

export function useApollo(initialState) {
    const store = useMemo(() => initializeApollo(initialState), [initialState])
    return store
}
