import React, { useEffect } from 'react';

const Home = (props) => {
    const { history } = props;
    useEffect(() => {
        history.push(`/proyectos`);
    }, []);
    return ( 
        <div></div>
    );
}
 
export default Home;