import { Fragment, useRef, useEffect, useState, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { USERS } from '../../Apollo/queries/users';
import { NOTIFICATION } from '../../Apollo/mutations/notification';
import { ASSIGNED_TO } from '../../Apollo/mutations/assignedTo';
import { useQuery, useMutation } from '@apollo/client';
import { proyectsActions } from "../../_actions";
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';


export const AssigmentModal = ({ project, open, setOpen, setProject, navigation }) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const cancelButtonRef = useRef(null);
    const [form, setForm] = useState(null);
    const [filter, setFilter] = useState('');
    const { data } = useQuery(USERS, { variables: { filter: { roleId: parseInt(3) } } });
    const [assignedToProject] = useMutation(ASSIGNED_TO);
    const [sendNotification] = useMutation(NOTIFICATION);

    useEffect(() => {
        if(!open) {
            setProject({});
            setForm(null);
        } else {
            project && setForm(project.assignedTo)
        }
    }, [open]);

    const filterUser = useMemo(() => {
        if(filter === '') return data && data.users;

        return data && 
            data.users.filter((user) => user.displayName.toLowerCase().includes(filter.toLowerCase()));
    }, [filter, data]);

    const handleChange = (e) => {
        setForm(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAssigned(form, project.id);
        setOpen(false);
    }

    const handleAssigned = async (user, project) => {
        const { email } = parseInt(user) === 0 ? '' : data.users.filter(user => parseInt(user.id) === parseInt(form))[0];
        const { data: dataAssigned } = await assignedToProject({
            variables: { 
                userId: parseInt(user),
                projectId: parseInt(project)
            }
        });

        if (dataAssigned) {
            addToast("Ejecutivo de cuentas asignado", { appearance: 'success', autoDismiss: true });

            if(parseInt(user) !== 0 ) {
                const { data: dataN } = await sendNotification({
                    variables: {
                        input: {
                            email: email,
                            title: "Asignación de proyecto",
                            movement: `Se te ha asignado el proyecto #${parseInt(project)}`,
                            redirect: `informacion/${parseInt(project)}`,
                            subject: `Se te ha asignado el proyecto #${parseInt(project)}`
                        }
                    }
                });
    
                if(dataN) {
                    addToast("Notificación enviada", { appearance: 'success', autoDismiss: true });
                }
            }

        }
    }

    const handleFilter = (e) => {
        setFilter(e.target.value);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
            
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Asignar proyecto
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Selecciona el ejecutivo al cual se le asignará este proyecto:
                                            </p>
                                        </div>
                                        <div className="mt-4 relative">
                                            <input type="text" onChange={handleFilter} placeholder="Buscar..." className="rounded border-2 transition duration-500 ease-in-out border-gray-300 px-2 py-2 w-full focus:outline-none focus:border-color-btn" />
                                            <button className="absolute right-3 top-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <form className="mt-4 max-h-40 overflow-y-auto" id='executives' onSubmit={handleSubmit}>
                                            <div className="flex items-center py-2 w-full">
                                                <input
                                                    style={{ accentColor: '#2B4BF2' }}
                                                    type="radio"
                                                    className="mx-2 cursor-pointer"
                                                    id="user_0"
                                                    value="0"
                                                    name="executive"
                                                    checked={parseInt(form) === 0}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="user_0" className="cursor-pointer">Sin ejecutivo de cuentas</label>
                                            </div>
                                            {
                                                filterUser && filterUser.map((user, index) => (
                                                    <div className="flex items-center py-2 w-full" key={`user_${index}`}>
                                                        <input
                                                            style={{ accentColor: '#2B4BF2' }}
                                                            type="radio"
                                                            className="mx-2 cursor-pointer"
                                                            id={`user_${user.id}`}
                                                            value={user.id}
                                                            name="executive"
                                                            checked={parseInt(form) === parseInt(user.id)}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor={`user_${user.id}`} className="cursor-pointer">{`${user.name} ${user.lastname}`}</label>
                                                    </div>
                                                ))
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 mr-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="submit"
                                    form="executives"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-color-btn text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3  sm:text-sm transition duration-500 ease-in-out"
                                >
                                    Asignar
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm transition duration-500 ease-in-out"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}