const servicesConstants = {

    GETALL_REQUEST: 'GETALL_SERVICE_REQUEST',
    GETALL_SUCCESS: 'GETALL_SERVICE_SUCCESS',
    GETALL_FAILURE: 'GETALL_SERVICE_FAILURE',

    GET_REQUEST: 'GET_SERVICE_REQUEST',
    GET_SUCCESS: 'GET_SERVICE_SUCCESS',
    GET_FAILURE: 'GET_SERVICE_FAILURE',

    GETALL_SUB_REQUEST: 'GETALL_SUBSERVICE_REQUEST',
    GETALL_SUB_SUCCESS: 'GETALL_SUBSERVICE_SUCCESS',
    GETALL_SUB_FAILURE: 'GETALL_SUBSERVICE_FAILURE',

    GET_SUB_REQUEST: 'GET_SUBSERVICE_REQUEST',
    GET_SUB_SUCCESS: 'GET_SUBSERVICE_SUCCESS',
    GET_SUB_FAILURE: 'GET_SUBSERVICE_FAILURE',
}
export { servicesConstants };