import { gql } from '@apollo/client';

export const SESSION = gql`
    query {
        session {
            id
            name
            lastname
            displayName
            email
            avatar
            enabled
            verified
            roleId
            roleSlug
            role
            permissions {
                title
                slug
            }
        }
    }
`;

export const USERS = gql`
    query($filter: userInput) {
        users(filter: $filter) {
            id
            name
            lastname
            displayName
            email
            role
            roleId
            roleSlug
            permissions{
                title
                slug
            }
        }
    } 
`;
