import { userConstants, inviteConstants, recoverConstants } from '../_constants';
import { userService } from '../_service';
import { alertActions } from "./alerts.actions";

export const userActions = {
    create,
    validateHashInvite,
    get,
    getAll,
    sendInvite,
    loggedIn,
    recoverPassword,
    validateRecoverPasswordHash,
    setNewPassword,
    logout,
    update
};

function getAll() {
    return (dispatch) => {
        dispatch(request());
        userService.getAll().then(
            (users) => {
                dispatch(success(users));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}
function get(id) {
    return (dispatch) => {
        dispatch(request());
        userService.get(id).then(
            (user) => {
                dispatch(success(user));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() {
        return { type: userConstants.GET_REQUEST };
    }
    function success(user) {
        return { type: userConstants.GET_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.GET_FAILURE, error };
    }
}
function create(dataRequest, history) {
    return (dispatch) => {
        dispatch(request());
        userService.create(dataRequest).then(
            (user) => {
                dispatch(success(user));
                dispatch(alertActions.show('success', "Usuario registrado correctamente."));
                history.push(`/login`);
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request() {
        return { type: userConstants.CREATE_SUCCESS };
    }
    function success(user) {
        return { type: userConstants.CREATE_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.CREATE_FAILURE, error };
    }
}
function update(dataRequest, id) {
    return (dispatch) => {

        dispatch(request());
        userService.update(dataRequest, id).then(
            (user) => {
                dispatch(success(user));
                dispatch(alertActions.show('success', "Información modificada."));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request() {
        return { type: userConstants.UPDATE_REQUEST };
    }
    function success(user) {
        return { type: userConstants.UPDATE_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.UPDATE_FAILURE, error };
    }
}
function validateHashInvite(hash) {
    return (dispatch) => {
        dispatch(request());
        userService.validateHashInvite(hash).then(
            (invite) => {
                dispatch(success(invite));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() {
        return { type: inviteConstants.VALIDATE_REQUEST };
    }
    function success(invite) {
        return { type: inviteConstants.VALIDATE_SUCCESS, invite };
    }
    function failure(error) {
        return { type: inviteConstants.VALIDATE_FAILURE, error };
    }
}
function sendInvite(dataRequest) {
    return (dispatch) => {
        dispatch(request());
        userService.sendInvite(dataRequest).then(
            (invite) => {
                dispatch(success(invite));
                dispatch(alertActions.show('success', "Invitación envida de forma correcta."));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', "Error: " +error.toString()));
            }
        );
    };
    function request() {
        return { type: inviteConstants.INVITE_REQUEST };
    }
    function success(invite) {
        return { type: inviteConstants.INVITE_SUCCESS, invite };
    }
    function failure(error) {
        return { type: inviteConstants.INVITE_FAILURE, error };
    }
}
function recoverPassword(email, history) {
    return (dispatch) => {
        dispatch(request());
        userService.recoverPassword(email).then(
            (recover) => {
                dispatch(success(recover));
                dispatch(alertActions.show('success', "Se envio un correo de recuperación al correo ingresado."));
                history.push(`/login`);
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', "Error: " +error.toString()));
            }
        );
    };
    function request() {
        return { type: recoverConstants.RECOVER_REQUEST };
    }
    function success(recover) {
        return { type: recoverConstants.RECOVER_SUCCESS, recover };
    }
    function failure(error) {
        return { type: recoverConstants.RECOVER_FAILURE, error };
    }
}
function validateRecoverPasswordHash(hash) {
    return (dispatch) => {
        dispatch(request());
        userService.validateRecoverPasswordHash(hash).then(
            (recover) => {
                dispatch(success(recover));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() {
        return { type: recoverConstants.VALIDATE_RECOVER_REQUEST };
    }
    function success(recover) {
        return { type: recoverConstants.VALIDATE_RECOVER_SUCCESS, recover };
    }
    function failure(error) {
        return { type: recoverConstants.VALIDATE_RECOVER_FAILURE, error };
    }
}
function setNewPassword(userId, password, hash, history) {
    return (dispatch) => {
        dispatch(request());
        userService.setNewPassword(userId, password, hash).then(
            (user) => {
                dispatch(success(user));
                dispatch(alertActions.show('success', "Se actualizo la contraseña correctamente."));
                history.push(`/login`);
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() {
        return { type: userConstants.GET_REQUEST };
    }
    function success(user) {
        return { type: userConstants.GET_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.GET_FAILURE, error };
    }
}
function loggedIn(data) {
    localStorage.setItem('user', JSON.stringify(data));
    return {
        type: 'LOGGED_IN',
        payload: data,
    };
}
function logout(history) {
    history.push(`/login`);
    return {
        type: 'LOGOUT'
    };
}
