import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";

export const servicesService = {
    services,
    service,
    subservices,
    subservice
};
const client = initializeApollo(null);

function services(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = ((dataRequest) ? ("(filter:" + JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:') + ")") : "");
    return client.query({
        query: gql`
        {
			services${parsedDataRequest}{
				id,
                title,
                businessId
			}
		}`
    }).then(response => {
        return response.data.services;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function service(id) {
    client.cache.reset();
    return client.query({
        query: gql`
        {
			service(id:${id}){
				id,
                title,
                businessId
			}
		}`
    }).then(response => {
        return response.data.service;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function subservices(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = ((dataRequest) ? ("(filter:" + JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:') + ")") : "");
    return client.query({
        query: gql`
        {
			subservices${parsedDataRequest}{
				id,
                title,
                group,
                serviceId
			}
		}`
    }).then(response => {
        return response.data.subservices;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function subservice(id) {
    client.cache.reset();
    return client.query({
        query: gql`
        {
			subservice(id:${id}){
				id,
                title,
                group,
                serviceId
			}
		}`
    }).then(response => {
        return response.data.subservice;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}