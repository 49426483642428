import React from 'react';

class CircularProgressBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      // Size of the enclosing square
      const sqSize = this.props.sqSize;
      // SVG centers the stroke width on the radius, subtract out so circle fits in square
      const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
      // Enclose cicle in a circumscribing square
      const viewBox = `0 0 ${sqSize} ${sqSize}`;
      // Arc length at 100% coverage is the circle circumference
      const dashArray = radius * Math.PI * 2;
      // Scale 100% coverage overlay with the actual percent
      const dashOffset = dashArray - dashArray * this.props.percentage / 100;
  
      return (
        <svg
            width={this.props.sqSize}
            height={this.props.sqSize}
            viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`} />
            <circle
              className="circle-progress"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset
              }} />
            <text className="text-gray-200 font-semibold"
            x="35%"
            y="40%"
            dy=".3em">Progreso</text>
            <text
              className="circle-text"
              x="55%"
              y="60%"
              dy=".3em"
              textAnchor="middle">
              {`${this.props.percentage}%`}
            </text>
        </svg>
      );
    }
  }
  
  CircularProgressBar.defaultProps = {
    sqSize: 100,
    percentage: 0,
    strokeWidth: 5
  };
  
  class Progress extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        percentage: 0
      };
  
      // this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    
  
    // handleChangeEvent(event) {
    //   this.setState({
    //     percentage: event.target.value
    //   });
    // }
  
    render() {
      return (
        <div>
            <CircularProgressBar
              strokeWidth="15"
              sqSize="200"
              percentage={this.props.percentage}/>
            {/* <div className="w-6/12">
                <div className="grid grid-cols-3 ml-6 flex">
                    <input type="button" onClick={()=>{
                        if(this.state.percentage>0){
                            this.setState({
                                percentage: this.state.percentage-1
                            })}
                        }
                    } className="rounded-full h-10 w-10 flex items-center justify-center bg-gray-200 cursor-pointer" value="-"/>
                    <input type="number" className="w-10 items-center justify-center text-center" 
                    onBlur={(event)=>{
                        if(event.target.value<0){
                            this.setState({
                                percentage: 0
                            })
                        }else if(event.target.value>100){
                            this.setState({
                                percentage: 100
                            })
                        }
                    }}
                    value={this.state.percentage} onChange={this.handleChangeEvent} min="0" max="100"/>
                    <input type="button" onClick={()=>{
                        if(this.state.percentage<100){
                            this.setState({
                                percentage: this.state.percentage-1
                            })}
                        }
                    } className="rounded-full h-10 w-10 flex items-center justify-center bg-gray-200 cursor-pointer" value="+"/>
                </div>
            </div> */}
          </div>
      );
    }
  }
  
  export { Progress };