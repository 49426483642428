import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";

export const userService = {
    create,
    validateHashInvite,
    getAll,
    get,
    sendInvite,
    recoverPassword,
    validateRecoverPasswordHash,
    setNewPassword,
    update
};
const client = initializeApollo(null);

function getAll() {
    client.cache.reset();
	return client.query({
		query: gql`
        {
			users{
				id,
                name,
                lastname,
                displayName,
                email,
                avatar,
                enabled,
                verified,
                roleId,
                role,
                roleSlug,
                createdAt,
                updatedAt,
                lastConnectionAt
                profile {
                    alias
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioSocial
                    domicilioFiscal
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    documentStatus {
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
			}
		}`
	}).then(response => {
		return response.data.users;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function get(id) {
	client.cache.reset();
	return client.query({
		query: gql`
        {
			user(id:${id}){
				id,
                name,
                lastname,
                email,
                avatar,
                enabled,
                verified,
                roleId,
                role,
                roleSlug,
                createdAt,
                updatedAt,
                lastConnectionAt
                profile {
                    alias
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioSocial
                    domicilioFiscal
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    documentStatus {
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
			}
		}`
	}).then(response => {
		return response.data.user;
	}).catch(function (e) {
		throw new Error(e.message);
    });
}
function create(dataRequest) {
	client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
	return client.mutate({
		mutation: gql`
        mutation{
            signupOperator(input:${parsedDataRequest}){
                id,
                name,
                lastname,
                email,
                avatar,
                enabled,
                verified,
                roleId,
                role,
                roleSlug,
                profile {
                    alias
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioSocial
                    domicilioFiscal
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    documentStatus {
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
                permissions{
                    title,
                    slug
                },
                createdAt,
                updatedAt,
                lastConnectionAt
            }
        }`
	}).then(response => {
		return response.data.signupOperator;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function update(dataRequest, id) {
	client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
	return client.mutate({
		mutation: gql`
        mutation{
            updateUser(id:${id},input:${parsedDataRequest}){
                id,
                name,
                lastname,
                email,
                avatar,
                enabled,
                verified,
                roleId,
                role,
                roleSlug,
                permissions{
                    title,
                    slug
                }
                profile {
                    alias
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioSocial
                    domicilioFiscal
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    documentStatus {
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
                createdAt,
                updatedAt,
                lastConnectionAt
            }
        }`
	}).then(response => {
		return response.data.updateUser;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function validateHashInvite(hash) {
    client.cache.reset();
	return client.query({
		query: gql`
        {
			invite(hash:"${hash}"){
				hash,
                email,
                roleId,
                available
			}
		}`
	}).then(response => {
		return response.data.invite;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function sendInvite(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = ((dataRequest)?("(input:"+JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:')+")"):"");
    return client.mutate({
		mutation: gql`
        mutation{
            sendInvite${parsedDataRequest}
        }`
	}).then(response => {
		return response.data.sendInvite;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function recoverPassword(email) {
    client.cache.reset();
    return client.mutate({
		mutation: gql`
        mutation{
            recoverPassword(email:"${email}")
        }`
	}).then(response => {
		return response.data.recoverPassword;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function validateRecoverPasswordHash(hash) {
    client.cache.reset();
	return client.query({
		query: gql`
        {
			validateRecoverPasswordHash(hash:"${hash}")
		}`
	}).then(response => {
		return response.data.validateRecoverPasswordHash;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}
function setNewPassword(userId, password, hash) {
    client.cache.reset();
    return client.mutate({
		mutation: gql`
        mutation{
            setNewPassword(user_id: ${userId}, password: "${password}", hash: "${hash}")
        }`
	}).then(response => {
		return response.data.setNewPassword;
	}).catch(function (e) {
		throw new Error(e.message);
	});
}