export function camelCase(str){
    return str
    .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
    .replace(/\s/g, '')
    .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}

export function getDateFormat(date){
    var dateAux = date.split('T');
    return dateAux[0].split("-").reverse().join("/") + ' - ' + dateAux[1].split('.')[0].substring(0, dateAux[1].split('.')[0].length - 3);
}