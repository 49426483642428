import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";

export const paymentsService = {
    getAll,
    get,
    create,
    update,
    deletePayment
};
const client = initializeApollo(null);

function getAll(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = ((dataRequest) ? ("(filter:" + JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:') + ")") : "");
    return client.query({
        query: gql`
        {
			payments${parsedDataRequest}{
				id,
                client{
                    id,
                    name,
                    lastname,
                    email,
                    avatar,
                    enabled,
                    verified,
                    roleId,
                    role,
                    roleSlug,
                    createdAt,
                    updatedAt,
                    lastConnectionAt
                },
                project{
                    id,
                    title,
                    description,
                    status,
                    createdAt,
                    businessId,
                    business,
                    serviceId,
                    service,
                    subserviceId,
                    subservice,
                    billingKindClient,
                    billingKindTarget,
                    favorite,
                    quoteFileUrl,
                    contractFileUrl,
                    section
                },
                duedate,
                createdat,
                xmlfileurl,
                pdffileurl,
                amount
			}
		}`
    }).then(response => {
        return response.data.payments;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function get(id) {
    client.cache.reset();
    return client.query({
        query: gql`
        {
			payment(id:${id}){
				id,
                client{
                    id,
                    name,
                    lastname,
                    email,
                    avatar,
                    enabled,
                    verified,
                    roleId,
                    role,
                    roleSlug,
                    createdAt,
                    updatedAt,
                    lastConnectionAt
                },
                project{
                    id,
                    title,
                    description,
                    status,
                    createdAt,
                    businessId,
                    business,
                    serviceId,
                    service,
                    subserviceId,
                    subservice,
                    billingKindClient,
                    billingKindTarget,
                    favorite,
                    quoteFileUrl,
                    contractFileUrl,
                    section
                },
                duedate,
                createdat,
                xmlfileurl,
                pdffileurl,
                amount
			}
		}`
    }).then(response => {
        return response.data.payment;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function create(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            createPayment(input:${parsedDataRequest}){
                id,
                client{
                    id,
                    name,
                    lastname,
                    email,
                    avatar,
                    enabled,
                    verified,
                    roleId,
                    role,
                    roleSlug,
                    createdAt,
                    updatedAt,
                    lastConnectionAt
                },
                project{
                    id,
                    title,
                    description,
                    status,
                    createdAt,
                    businessId,
                    business,
                    serviceId,
                    service,
                    subserviceId,
                    subservice,
                    billingKindClient,
                    billingKindTarget,
                    favorite,
                    quoteFileUrl,
                    contractFileUrl,
                    section
                },
                duedate,
                createdat,
                xmlfileurl,
                pdffileurl,
                amount
            }
        }`
    }).then(response => {
        return response.data.createPayment;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function update(dataRequest, id) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            updatePayment(id:${id}, input:${parsedDataRequest}){
                id,
                client{
                    id,
                    name,
                    lastname,
                    email,
                    avatar,
                    enabled,
                    verified,
                    roleId,
                    role,
                    roleSlug,
                    createdAt,
                    updatedAt,
                    lastConnectionAt
                },
                project{
                    id,
                    title,
                    description,
                    status,
                    createdAt,
                    businessId,
                    business,
                    serviceId,
                    service,
                    subserviceId,
                    subservice,
                    billingKindClient,
                    billingKindTarget,
                    favorite,
                    quoteFileUrl,
                    contractFileUrl,
                    section
                },
                duedate,
                createdat,
                xmlfileurl,
                pdffileurl,
                amount
            }
        }`
    }).then(response => {
        return response.data.updatePayment;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}
function deletePayment(id) {
    client.cache.reset();
    return client.mutate({
        mutation: gql`
        mutation{
            deletePayment(id:${id}){
                id,
                client{
                    id,
                    name,
                    lastname,
                    email,
                    avatar,
                    enabled,
                    verified,
                    roleId,
                    role,
                    roleSlug,
                    createdAt,
                    updatedAt,
                    lastConnectionAt
                },
                project{
                    id,
                    title,
                    description,
                    status,
                    createdAt,
                    businessId,
                    business,
                    serviceId,
                    service,
                    subserviceId,
                    subservice,
                    billingKindClient,
                    billingKindTarget,
                    favorite,
                    quoteFileUrl,
                    contractFileUrl,
                    section
                },
                duedate,
                createdat,
                xmlfileurl,
                pdffileurl,
                amount
            }
        }`
    }).then(response => {
        return response.data.deletePayment;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}