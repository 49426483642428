import { paymentsConstants } from '../_constants';
let items = [];
let item = {};
const initialState = { loading: true, items, item };

export function payments(state = initialState, action) {
	switch (action.type) {
		case paymentsConstants.GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case paymentsConstants.GET_SUCCESS:
			return {
				...state,
				loading:false,
				item: ((action.payment!==null)?action.payment:{})
			};
		case paymentsConstants.GET_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case paymentsConstants.GETALL_REQUEST:
			return {
				...state,
				loading: true
			};
		case paymentsConstants.GETALL_SUCCESS:
			return {
				...state,
				loading:false,
				items: ((action.payments!==null)?action.payments:[]),
			};
		case paymentsConstants.GETALL_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case paymentsConstants.CREATE_REQUEST:
			return {
				...state,
				loading: true
			};
		case paymentsConstants.CREATE_SUCCESS:
			return {
				...state,
				loading:false,
				item: action.payment,
				items: state.items.concat(action.payment)
			};
		case paymentsConstants.CREATE_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case paymentsConstants.UPDATE_REQUEST:
			return {
				...state,
				loading:true,
			};
		case paymentsConstants.UPDATE_SUCCESS:
			return {
				...state,
				loading:false,
				item: action.payment,
				items: state.items.map(item => {
					if(item.id === action.payment.id){
						item = action.payment;
					}
					return item;
				})
			};
		case paymentsConstants.UPDATE_FAILURE:
			return {
				...state,
				item: { ...item},
				loading:false,
				items: state.items.map(item =>
					item.id === action.payment.id
					? { ...item}
					: item
				),
				error: action.error
			};
		case paymentsConstants.DELETE_REQUEST:
            return {
                ...state,
                items: state.items.map(payment =>
                    payment.id === action.id
                        ? { ...payment, deleting: true }
                        : payment
                )
            };
		case paymentsConstants.DELETE_SUCCESS:
            return {
                items: state.items.filter(payment => payment.id !== action.id)
            };
        case paymentsConstants.DELETE_FAILURE:
            return {
                ...state,
                items: state.items.map(payment => {
                    if (payment.id === payment.id) {
                        const { deleting, ...paymentCopy } = payment;
                        return { ...paymentCopy, deleteError: action.error };
                    }

                    return payment;
                })
            };
		default:
			return state
	}
}