import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions,  } from "../../_actions";
import { Dialog, Transition } from '@headlessui/react';
import { useToasts } from 'react-toast-notifications';

function Listado(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const user = useSelector(state => state.authentication);
    useEffect(() => {
        dispatch(userActions.getAll());
    }, []);
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const invite = useSelector(state => state.invite);
    const users = useSelector(state => state.users);
    const alert = useSelector(state => state.alert);
    const [inputs, setInputs] = useState({
        correo:'',
        role:0
    })
    const [submit, setSubmit] = useState({state:false});
    useEffect(() => {
        if (alert.message && alert.message !== "") {
          addToast(alert.message, { appearance: alert.severity });
        }
      }, [alert]);

    useEffect(() => {
        user &&
            user.user &&
                user.user.roleSlug === 'executive' || user.user.roleSlug === 'legal' && props.history.push('/');
    }, [])
    loads();
    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    }
    const sendInvite = () => {
        if(inputs.correo !== "" && parseInt(inputs.role) > 0){
            setSubmit({...submit, 'state':true});
            const dataRequest = {
                email: inputs.correo,
                roleId: parseInt(inputs.role)
            }
            dispatch(userActions.sendInvite(dataRequest));
            closeModal();
        }
    }
    function loads() {
        if(!invite.loading && invite.items && submit.state){
            setSubmit({...submit, 'state':false});
        }
    }
    const _renderModal = () => {
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={closeModal}

                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                  </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="my-3 text-center sm:mt-0 sm:text-left" align="center">
                                            <Dialog.Title as="h3" align="center">
                                                <div>
                                                    <div align="right">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={closeModal}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                    <div className="text-3xl leading-6 font-semibold text-gray-900 mx-20 pt-10">
                                                        <div>Enviar invitaci&oacute;n</div>
                                                    </div>
                                                </div>
                                            </Dialog.Title>
                                            <div className="mt-6 mx-10">
                                               <input name="correo" id="correo" className="text-sm shadow bg-white border rounded w-full py-2 px-3 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline mb-2" type="text" placeholder="Correo eléctronico" onChange={handleChange}/>
                                               <select name="role" id="role" className="text-sm shadow bg-white border rounded w-full py-2 px-3 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline" onChange={handleChange}>
                                                    <option> 
                                                        Seleccione el rol
                                                    </option>
                                                    <option value={2}>Administrador</option>
                                                    <option value={3}>Ejecutivo de Cuenta</option>
                                                    <option value={5}>Jurídico</option>
                                               </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 pb-3 mb-8" >
                                    <div className="w-full px-1" align="center">
                                        <button
                                            type="button"
                                            className="text-color-btn border-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                            disabled={!submit}
                                            onClick={() => setOpen(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            type="button"
                                            className="text-white bg-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                            disabled={!submit}
                                            onClick={() => sendInvite()}>
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const _render = () => {
        if(users.loading){
            return(
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        }else{
            if(users.items && users.items.length>0){
                return(
                    <table className="table-auto border">
                        <thead>
                            <tr>
                                <th className="w-2/12 border">Nombre</th>
                                <th className="w-2/12 border">Apellidos</th>
                                <th className="w-4/12 border">Correo El&eacute;ctronico</th>
                                <th className="w-1/12 border">tipo</th>
                                <th className="w-1/12 border">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.items.map((item, index)=>{
                                return(
                                <tr key={'row_-'+index}>
                                    <td className="border">{item.name}</td>
                                    <td className="border">{item.lastname}</td>
                                    <td className="border">{item.email}</td>
                                    <td className="border">{item.role}</td>
                                    <td className="border">{item.enabled?'Activo':'Inactivo'}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                )
            }
        }
    }
    return (
        <div className="min-h-screen flex justify-start bg-white py-12 sm:px-6 lg:px-8">
            <div className="w-full px-28">
                <div className="grid grid-cols-3">
                    <div className="col-span-2" align="center">
                        <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                            Usuarios
                        </h2>
                    </div>
                    <div className="pt-6 " align="center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-color-btn h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={openModal}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                        </svg>
                    </div>
                </div>
                <div className="pt-8">
                    {_render()}
                </div>
            </div>
            {_renderModal()}
        </div>
    )
}
export { Listado };