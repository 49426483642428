import React, { useState, createRef, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPopper } from "@popperjs/core";
import { proyectsActions, notificationsActions, userActions } from "../../_actions";
import { Dialog, Transition } from '@headlessui/react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { useToasts } from 'react-toast-notifications';

function MyPopover({ popoverReason, reason }) {
    const popoverRef = createRef();
    return (
        <div
            className={
                (popoverReason ? "" : "hidden ") +
                "absolute shadow-2xl bg-white border-0 p-2 z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
            }
            ref={popoverRef}
        >
            <div>
                <div align="center">
                    <h5 className="font-bold text-red-700">Motivo de rechazo</h5>
                    <p className="pt-3 text-gray-700 text-left mx-4 text-sm">{ reason }</p>
                </div>
            </div>
        </div>
    )
}

function CardDocumento(props) {
    const { addToast } = useToasts();
    const scrollModePluginInstance = scrollModePlugin();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const dispatch = useDispatch();
    const { item, notif, updateStateSubmit } = props;
    const [inputs, setInputs] = useState({
        motivo:''
    });
    const [popoverShow, setPopoverShow] = useState(false);
    const [popoverReason, setPopoverReason] = useState(false); //
    const [popType, setPopType] = useState(1);
    const [open, setOpen] = useState(false);
    const [cargado, setCargado] = useState(false);
    const file = useSelector(state => state.files);
    const cancelButtonRef = useRef(null);
    const btnRef = createRef();
    const popoverRef = createRef();
    const fileInput = useRef(null);
    const [b64, setB64] = useState(null);
    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "bottom-start"
        });
        setPopoverShow(true);
    };
    const closePopover = () => {
        setPopoverShow(false);
    };
    const handleClick = () => {
        fileInput.current.click()
    }
    const handleChange = ({target}) => {
        const {name, value} = target;
        setInputs({...inputs, [name]:value});
    }
    const saveFile = (files) => {
        if (files[0].size <= 50000000) {
            let dataRequest ={
                email: notif.email,
                title:  notif.title?notif.title:'Actualización del proyecto',
                movement: "se subio el archivo " + item.name,
                redirect:`${item.redirect?item.redirect:'details'}/${item.projectId}?redirect=true`,
                subject: `Actualización de proceso en el proyecto #${item.projectId}`
            };
            dispatch(notificationsActions.sendNotificationClient(dataRequest));
            dataRequest.email ='';
            dataRequest.redirect =`documentos/${item.projectId}`;
            dataRequest.subject = `Actualización de proceso en el proyecto #${item.projectId}`;
            dispatch(notificationsActions.sendNotificationOperator(dataRequest, notif.fav));
            dispatch(proyectsActions.saveDocument(files[0], item.projectId, item.campo, item.extra));
        } else {
            addToast("El archivo no puede pesar mas de 50 MB.", { appearance: 'error' });
        }
    }
    const openModal = () => {
        dispatch(proyectsActions.getDocument(item.url));
        setCargado(false);
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
        setCargado(false);
        setB64(null);
    };
    const base64toBlob = (data) => {

        const bytes = atob(data);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };
    const changeStatusDoc = (state) => {
        if(state !== 'rejected'){
            let dataRequest ={
                email: notif.email,
                title:  notif.title?notif.title:'Actualización del proyecto',
                movement: "se realizo un cambio de estatus en el archivo " + item.name,
                redirect:`details/${item.projectId}?redirect=true`,
                subject: `Actualización de un documento en el proyecto #${item.projectId}`
            };
            dispatch(notificationsActions.sendNotificationClient(dataRequest));
            dataRequest.email ='';
            dataRequest.redirect =`documentos/${item.projectId}`;
            dataRequest.subject = `Actualización de un documento en el proyecto #${item.projectId}`;
            dispatch(notificationsActions.sendNotificationOperator(dataRequest, notif.fav));
        }else{
            let dataRequest ={
                email: notif.email,
                title:  notif.title?notif.title:'Actualización del proyecto',
                movement: "se realizo un cambio de rechazo en el archivo " + item.name + "motivo: " + inputs.motivo,
                redirect:`details/${item.projectId}?redirect=true`,
                subject: `Actualización de un documento en el proyecto #${item.projectId}`
            };
            dispatch(notificationsActions.sendNotificationClient(dataRequest));
            dataRequest.email ='';
            dataRequest.redirect =`documentos/${item.projectId}`;
            dataRequest.subject = `Actualización de un documento en el proyecto #${item.projectId}`;
            dispatch(notificationsActions.sendNotificationOperator(dataRequest, notif.fav));
        }

        if(item.cliente && item.cliente > 0) {
            dispatch(userActions.update({
                profile:{
                    documentStatus:{
                        [item.column]:state
                    }
                }}, item.cliente));
            updateStateSubmit();
        }else{
            if (state !== 'rejected') {
                dispatch(proyectsActions.update({
                    documentStatus:{
                        [item.column]: state,
                    }}, item.projectId));
            } else {
                dispatch(proyectsActions.update({
                    documentStatus:{
                        [item.column]: state,
                        rejectionReasonSignedContract: inputs.motivo
                    }}, item.projectId));
            }
        }

        setInputs({ ...inputs, motivo: '' });
        closePopover();
    }
    const _renderModal = () => {
        var items = [];
        if (file.loading) {
            items.push(
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else if (!file.loading && file.item.body && file.item.body !== "") {
            if (b64 === null && !cargado && open) {
                const blob = base64toBlob(file.item.body);
                const url = URL.createObjectURL(blob);
                setCargado(true);
                setB64(url);
            } else if (b64 !== null) {
                items.push(
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <Viewer
                            fileUrl={b64}
                            plugins={[
                                defaultLayoutPluginInstance,
                                scrollModePluginInstance,
                            ]}
                            />
                    </div>
                )
            } else {
                items.push(
                    <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-400 rounded w-full"></div>
                            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-400 rounded"></div>
                                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={closeModal}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                  </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-6xl w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" align="center">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900" align="center">
                                                {item.name}
                                            </Dialog.Title>
                                            <div>
                                                {items}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full px-4 pb-3">
                                    <div className="px-1" align="center">
                                        <button
                                            type="button"
                                            className="p-4 text-color-btn border-color-btn border rounded-lg py-2 text-sm"
                                            onClick={() => closeModal()}
                                            ref={cancelButtonRef}>
                                            Cerrar
                            </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const _renderPopOver = () => {
        return (
            <div
                className={
                    (popoverShow ? "" : "hidden ") +
                    "shadow-lg bg-white border-0 p-2 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                }
                ref={popoverRef}>
                {popType === 1 &&
                    <div align="center">
                        <div>
                            <h5 className="font-bold">{item.name}</h5>
                            <p className="pt-3 text-gray-700 text-left mx-4 text-sm">Por favor, asegurate de inspeccionar detenidamente el documento recibido.</p>
                        </div>
                        <div className="p-3" align="center">
                            <div className="grid grid-cols-2">
                                <div className="mx-1">
                                    <input type="button" value="Rechazar" onClick={() => {setPopType(2);}} className="w-full p-1 bg-white border-color-btn text-sm text-color-btn border-2 rounded-md px-4 mr-2 cursor-pointer" />
                                </div>
                                <div className="mx-1">
                                    <input
                                        type="button"
                                        value="Aceptar"
                                        onClick={() => {
                                            changeStatusDoc("acepted");
                                        }}
                                        className="w-full p-1 bg-color-btn text-white text-sm border-2 border-color-btn rounded-md px-6 mr-2 cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {popType === 2 &&
                    <div>
                        <div align="center">
                            <h5 className="font-bold">Motivo de rechazo</h5>
                            <p className="pt-3 text-gray-700 text-left mx-4 text-sm">Este mensaje sera leido por el cliente, aseg&uacute;rate de ser lo mas descriptivo posible para ayudar al cliente a resolverlo.</p>
                        </div>
                        <div className="mx-4 my-4">
                            <span className="text-bold text-sm">Motivo:</span>
                            <input type="text" className="w-full border bg-gray-100 rounded-md p-1 mt-2" name="motivo" id="motivo" value={inputs.motivo} onChange={handleChange}/>
                        </div>
                        <div className="p-3" align="center">
                            <div className="grid grid-cols-2">
                                <div className="mx-1">
                                    <input type="button" value="Atras" onClick={() => setPopType(1)} className="w-full p-1 bg-white border-color-btn text-sm text-color-btn border-2 rounded-md px-4 mr-2 " />
                                </div>
                                <div className="mx-1">
                                    <input type="button" value="Aceptar" onClick={()=>{changeStatusDoc("rejected"); closePopover();}} className="w-full p-1 bg-color-btn text-white text-sm border-2 border-color-btn rounded-md px-6 mr-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
    const render_state = () => {
        let color = '';
        switch(item.state){
            case 'acepted':
                color = "#458D51";
            break;
            case 'rejected':
                color = "#F11F18";
            break;
            default:
                if(item.url){
                    color = "#E8AF60";
                }else{
                    color = "#868686";
                }
            break;
        }
        return (
            <svg width="71" height="70" viewBox="0 0 81 80" fill={color} xmlns="http://www.w3.org/2000/svg">
                <path d="M40.315 5.00049C20.8917 5.00049 5.14258 20.6724 5.14258 40.0005C5.14258 59.3286 20.8917 75.0005 40.315 75.0005C59.7383 75.0005 75.4874 59.3286 75.4874 40.0005C75.4874 20.6724 59.7383 5.00049 40.315 5.00049ZM55.5067 28.5708L38.9725 51.3833C38.7414 51.7043 38.4367 51.9658 38.0838 52.1462C37.7309 52.3266 37.3398 52.4207 36.943 52.4207C36.5462 52.4207 36.1551 52.3266 35.8022 52.1462C35.4493 51.9658 35.1446 51.7043 34.9135 51.3833L25.1233 37.8833C24.825 37.4692 25.1233 36.8911 25.6337 36.8911H29.3158C30.1166 36.8911 30.8781 37.2739 31.3492 37.9302L36.9391 45.6489L49.2808 28.6177C49.7519 27.9692 50.5056 27.5786 51.3142 27.5786H54.9963C55.5067 27.5786 55.805 28.1567 55.5067 28.5708Z" />
            </svg>
        )
    }
    return (
        <div className="rounded-2xl shadow-lg py-4 pr-2  h-auto m-4">
            <div className={"grid " + (item.btn ? "grid-cols-5" : "grid-cols-4")}>
                <div style={{ paddingTop: '50%' }} className="pr-2">
                    {/* <img
                        src={icon}
                        alt="icon"
                        className="fill-color-btn" /> */}
                    {render_state()}
                </div>
                <div className="col-span-3 pl-8" align="center">
                    <h5 className="font-normal font-normal py-2">{item.name}</h5>
                    <h5 className="font-normal text-sm text-gray-500">{item.descripcion}</h5>
                    {
                        item.rejectValidation === 'rejected' && item.reject && (
                            <>
                                <div onMouseEnter={() => setPopoverReason(true)} onMouseLeave={() => setPopoverReason(false)}>
                                    <h5 className="line-clamp-1 font-normal text-sm text-gray-500 cursor-default">
                                        <strong className="text-red-700">Rechazo: </strong>
                                        {item.reject}
                                    </h5>
                                </div>
                                <MyPopover popoverReason={popoverReason} reason={item.reject} />
                            </>
                        )
                    }
                </div>
                {item.btn &&
                    <div>
                        <div className="hover:bg-gray-300 rounded w-min w-max cursor-pointer" onClick={() => {
                            setPopType(1);
                            popoverShow ? closePopover() : openPopover();
                        }}
                            ref={btnRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                            </svg>
                        </div>
                    </div>
                }
            </div>
            <div className="pt-6">
                <div className="pt-2" align="center">
                    {!item.cliente?
                        (
                            !item.url ?
                                (<h5 className="text-color-btn font-bold cursor-pointer" onClick={handleClick}>Subir documento</h5>) :
                                (
                                    <div className="grid grid-cols-2">
                                        <h5 className="text-color-btn font-bold cursor-pointer" onClick={handleClick}>Sustituir</h5>
                                        <h5 className="text-color-btn font-bold cursor-pointer" onClick={openModal}>Ver</h5>
                                    </div>
                                )
                        ) :
                        (
                            !item.url ?
                                (<h5 className="text-color-btn font-bold">Sin documento</h5>) :
                                (
                                    <h5 className="text-color-btn font-bold cursor-pointer" onClick={openModal}>Ver</h5>
                                )
                        )                    
                    }
                    
                </div>
            </div>
            <input type="file" onChange={({ target: { files } }) => saveFile(files)} ref={fileInput} style={{ display:'none' }} accept=".pdf"></input>
            {_renderPopOver()}
            {_renderModal()}
        </div>
    )
}
export { CardDocumento };