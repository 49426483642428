import { userConstants } from '../_constants';
let items = [];
let item = {};
const initialState = { loading: true, items, item };

export function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.user !== null ? action.user : {},
            };
        case userConstants.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.users !== null ? action.users : [],
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userConstants.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.user,
                items: state.items.concat(action.user),
            };
        case userConstants.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userConstants.UPDATE_REQUEST:
			return {
				...state,
				loading:true,
			};
		case userConstants.UPDATE_SUCCESS:
			return {
				...state,
				loading:false,
				item: action.user,
				items: state.items.map(item => {
					if(item.id === action.user.id){
						item = action.user;
					}
					return item;
				})
			};
		case userConstants.UPDATE_FAILURE:
			return {
				...state,
				item: { ...item},
				loading:false,
				items: state.items.map(item =>
					item.id === action.user.id
					? { ...item}
					: item
				),
				error: action.error
			};
        default:
            return state;
    }
}
