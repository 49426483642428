import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../_actions";
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';

function NuevaContrasena(props) {
    const dispatch = useDispatch();
    const recover = useSelector(state => state.recover);
    const [submit, setSubmit] = useState(false);
    let { hash } = useParams();
    const { history } = props;
    const [inputs, setInputs] = useState({
        password: '',
        passwordC: '',
    });
    const [inputsError, setInputsError] = useState({
        items: {
            password: '',
            errorpassword: false,
            passwordC: '',
            errorpasswordC: false,
        }
    });
    useEffect(() => {
        if (hash === null || hash === undefined) {
            history.push(`/login`);
        }
        dispatch(userActions.validateRecoverPasswordHash(hash));
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value })
    }
    const handleSubmit = () => {
        if (!inputsError.items.errorpassword && !inputsError.items.errorpasswordC) {
            if(!valEmpty()){
                setSubmit(true);
                dispatch(userActions.setNewPassword(recover.item, inputs.password, hash, history));
                setTimeout(() => {
                    setSubmit(false);
                }, 6000);
            }
        }
    }
    const valEmpty = () => {
        let error = false;
        var errorsAux = inputsError;
        errorsAux.items.errorpassword = inputs.password === '';
        error = error ? error : (errorsAux.items.errorpassword ? true : false);
        errorsAux.items.password = errorsAux.items.errorpassword ? "Contraseña requerida" : '';
        errorsAux.items.errorpasswordC = inputs.passwordC === '';
        error = error ? error : (errorsAux.items.errorpasswordC ? true : false);
        errorsAux.items.passwordC = errorsAux.items.errorpasswordC ? "Confirmación requerido" : '';
        setInputsError({ ...inputsError, 'items': errorsAux.items });
        return error;
    }
    const handleBlur = (e) => {
        const { value, name } = e.target;
        if (name === 'password') {
            var errorsAux = inputsError;
            if (value.length < 8) {
                errorsAux.items.password = 'La contraseña debe contener almenos 8 caracteres.'
                errorsAux.items.errorpassword = true;
            } else {
                errorsAux.items.password = ''
                errorsAux.items.errorpassword = false;
            }
            setInputsError({ ...inputsError, 'items': errorsAux.items });
        } else if (name === 'passwordC') {
            var errorsAux = inputsError;
            if (inputs.password !== value) {
                errorsAux.items.passwordC = 'Las contraseñas no coinciden.'
                errorsAux.items.errorpasswordC = true;
            } else {
                errorsAux.items.passwordC = ''
                errorsAux.items.errorpasswordC = false;
            }
            setInputsError({ ...inputsError, 'items': errorsAux.items });
        }
    }
    const _render = () => {
        if (recover.loading) {
            return (
                <div className="p-4 max-w-md w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-400 rounded w-full"></div>
                            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-400 rounded"></div>
                                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (recover.item > 0) {
                return (
                    <div>
                        <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
                            <div className="border border-gray-300 rounded p-10 w-1/4">
                                <div align="center">
                                    Cambiar contrase&ntilde;a
                        </div>
                                <div className="w-full">
                                    <input type="password" name="password" value={inputs.email} onChange={handleChange} id="password" placeholder="Nueva contraseña"
                                        className={
                                            inputsError.items.errorpassword
                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline my-4'
                                        } onBlur={handleBlur} />
                                    {inputsError.items.errorpassword ? (
                                        <span className="text-red-700 text-xs">
                                            {'*' +
                                                inputsError.items.password}
                                        </span>
                                    ) : null}
                                    <input type="password" name="passwordC" value={inputs.email} onChange={handleChange} id="passwordC" placeholder="Confirmación de contraseña"
                                        className={
                                            inputsError.items.errorpasswordC
                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline my-4'
                                        } onBlur={handleBlur} />
                                    {inputsError.items.errorpasswordC ? (
                                        <span className="text-red-700 text-xs">
                                            {'*' +
                                                inputsError.items.passwordC}
                                        </span>
                                    ) : null}
                                </div>
                                <div align="center">
                                    {submit ? (
                                        <div>
                                            <Loader
                                                type="ThreeDots"
                                                color="#50afbd"
                                                height={65}
                                                width={50}
                                                timeout={0}
                                            />
                                        </div>
                                    ) :
                                        (<input type="button" value="Recuperar" onClick={() => handleSubmit()} className="bg-color-btn text-white p-2 rounded cursor-pointer shadow-2xl hover:shadow-none" />)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="min-h-screen h-full  text-center justify-center pt-40 font-light mt-10 align-middle" align="center">
                        <h1 className="font-poppin  font-bold text-4xl">
                            Enlace de recuperaci&oacute;n invalido.
                        </h1>
                        <h4 className="text-2xl">
                            Solicita una nuevamente la recuperaci&oacute;n de tu contrase&ntilde;a.
                        </h4>
                    </div>
                )
            }
        }
    }
    return (
        <div>
            {_render()}
        </div>
    )
}

export { NuevaContrasena }