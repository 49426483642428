import { gql } from '@apollo/client';

export const ASSIGNED_TO = gql`
    mutation($userId: Int!, $projectId: Int!){
        assignedToProject(userId: $userId, projectId: $projectId){
            id
            title
            description
            clientId
        }
    }
`;
