import React from 'react';

import Router from './Router';
import '../index.css';

function App() {
    return <Router />;
}

export { App };
