import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";
require('dotenv').config({ path: require('find-config')('.env') })

export const filesService = {
    uploadFile,
    downloadFile
};
const client = initializeApollo(null);

function uploadFile(archivo) {
    client.cache.reset();
    return client.mutate({
        mutation: gql`
        mutation uploadFile($input: FileInput!){
            uploadFile(input: $input)
        }`,
        variables:{
            input:{
                folder: process.env.REACT_APP_DOCUMENTS_FOLDER,
                file: archivo
            }
        }
    }).then(response => {
        return response.data.uploadFile;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}


function downloadFile(url) {
    client.cache.reset();
    return client.query({
        query: gql`
        {
			downloadFile(url:"${url}"){
				length
                type
                body
			}
		}`
    }).then(response => {
        return response.data.downloadFile;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}