import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pago } from "./Pago";
import { paymentsActions, proyectsActions, notificationsActions } from "../../_actions";
import { Dialog, Transition } from '@headlessui/react';
import NumberFormat from "react-number-format";
import { useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import xml from "../../_helpers/svgs/xml.png";
import pdf from "../../_helpers/svgs/archivo-pdf.png";
import { useToasts } from 'react-toast-notifications';
import Loader from 'react-loader-spinner';
import { Progress } from "./progress";

function HistorialPagos(props) {
  const { id } = useParams();
  const { history } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    if (id === null || id === undefined) {
      history.push(`/proyectos`);
    }
    dispatch(paymentsActions.getAll({ projectid: parseInt(id) }));
    dispatch(proyectsActions.get(parseInt(id)));
  }, []);
  const [open, setOpen] = useState(false);
  const alert = useSelector(state => state.alert);
  const payments = useSelector(state => state.payments);
  const projects = useSelector(state => state.projects);
  const cancelButtonRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const [inputs, setInputs] = useState({
    fechaPago: '',
    monto: 0,
    pdf: null,
    xml: null
  });
  const fileXmlInput = useRef(null);
  const filePdfInput = useRef(null);
  useEffect(() => {
    if (!payments.loading) {
      setSubmit(false);
    }
  }, [payments]);
  useEffect(() => {
    if (alert.message && alert.message !== "") {
      addToast(alert.message, { appearance: alert.severity });
    }
  }, [payments]);
  const updateStateSubmit = () => {
    setSubmit(true);
  }
  const handleChange = ({ name, value }) => {
    setInputs({ ...inputs, [name]: value });
  }
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const addfile = (files, name) => {
    setInputs({ ...inputs, [name]: files[0] })
  }
  const savePayment = () => {
    if (inputs.fechaPago !== "" && inputs.monto !== "") {
      setSubmit(true);
      if (inputs.pdf !== null && inputs.xml !== null) {
        let dataRequest = {
          projectid: parseInt(id),
          clientid: parseInt(projects.item.client.id),
          duedate: inputs.fechaPago.split("/").reverse().join("-"),
          amount: parseFloat(inputs.monto.replaceAll(",", "").replaceAll("$", "")),
          xml: inputs.xml,
          pdf: inputs.pdf
        };
        dispatch(paymentsActions.createWithFiles(dataRequest));
      } else {
        let dataRequest = {
          projectid: parseInt(id),
          clientid: parseInt(projects.item.client.id),
          duedate: inputs.fechaPago.split("/").reverse().join("-"),
          amount: parseFloat(inputs.monto.replaceAll(",", "").replaceAll("$", ""))
        };
        dispatch(paymentsActions.create(dataRequest));
      }
      let dataRequest = {
        email: projects.item.client.email,
        title: projects.item.title ? projects.item.title : 'Actualización del proyecto',
        movement: 'Se agrego una factura de pago al proyecto.',
        redirect: `dashboard/${projects.item.id}?redirect=true`,
        subject: `Nuevo pago en el proyecto #${projects.item.id}`
      };
      dispatch(notificationsActions.sendNotificationClient(dataRequest));
      dataRequest.email = '';
      dataRequest.redirect = `documentos/${projects.item.id}`;
      dataRequest.subject = `Nuevo pago en el proyecto #${projects.item.id}`;
      dispatch(notificationsActions.sendNotificationOperator(dataRequest, projects.item.favorite));
      let inputsAux = {
        fechaPago: '',
        monto: 0,
        pdf: null,
        xml: null
      }
      setInputs(inputsAux);
      closeModal();
    } else {
      addToast("La fecha de pago y el monto son requeridos.", { appearance: 'error' });
    }
  }
  const handleClickFile = (e) => {
    const { name } = e.target
    switch (name) {
      case 'xml':
        fileXmlInput.current.click()
        break;
      case 'pdf':
        filePdfInput.current.click()
        break;
    }
  }
  const _renderModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
                  </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" align="center">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900" align="center">
                        Registrar un pago
                            </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm font-normal">
                          Introduce el monto exacto del pago, la fecha en la que fue realizada y sube la factura
                              </p>
                      </div>
                      <div>
                        <h6 className="font-semibold pt-2">Fecha de pago:</h6>
                        <MaskedInput
                          mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                          placeholder={'dd/mm/aaaa'}
                          showMask={false}
                          className="w-full p-2 mt-2 border-gray-300 border rounded"
                          name="fechaPago"
                          id="fechaPago"
                          value={inputs.fechaPago} onChange={({ target }) => handleChange(target)}
                        />
                        <h6 className="font-semibold pt-2">Monto pagado:</h6>
                        <NumberFormat className="w-full p-2 mt-2 border-gray-300 border rounded" name="monto" id="monto" value={inputs.monto} onChange={({ target }) => handleChange(target)} fixedDecimalScale={true} decimalScale={2} displayType={'input'} thousandSeparator={true} prefix={'$'} />
                        <h6 className="font-semibold pt-2">Factura:</h6>
                        <div className="grid grid-cols-2 pt-2 pb-2 cursor-pointer" align="center">
                          <div className="pr-2 has-tooltip" align="center">
                            <span className='tooltip rounded shadow-lg p-1 bg-gray-200' style={
                              inputs.pdf ? {} : { display: 'none' }
                            }>{inputs.pdf ? inputs.pdf.name : ""}</span>
                            <img src={pdf} alt="pdf" name="pdf" id="pdf" className="w-1/3 cursor-pointer" onClick={handleClickFile} />
                          </div>
                          <div className="has-tooltip" align="center">
                            <span className='tooltip rounded shadow-lg p-1 bg-gray-200' style={
                              inputs.xml ? {} : { display: 'none' }
                            }>{inputs.xml ? inputs.xml.name : ""}</span>
                            <img src={xml} alt="xml" name="xml" id="xml" className="w-1/3 cursor-pointer" onClick={handleClickFile} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 px-4 pb-3">
                  <div className="w-full px-1">
                    <button
                      type="button"
                      className="w-full text-color-btn border-color-btn border rounded-lg py-2 text-sm"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef}>
                      Cancelar
                    </button>
                  </div>
                  <div className="w-full px-1">
                    <button
                      type="button"
                      className="w-full text-white bg-color-btn border rounded-lg py-2 text-sm"
                      onClick={savePayment}>
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  const renderPayments = () => {
    let items = [];
    if (payments.loading) {
      items.push(
        <div className="animate-pulse flex space-x-4 mx-10 mb-8" key="skeleton_-1">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-400 rounded w-full"></div>
            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-400 rounded"></div>
              <div className="h-4 bg-gray-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      )
    } else {
      if (payments.items && payments.items.length > 0) {
        payments.items.map((item, index) => {
          if (item) {
            items.push(<Pago item={item} key={"pago_-" + index} id={projects.item.id} updateStateSubmit={updateStateSubmit} />);
          }
        });
      }
    }
    return items;
  }
  const _render_resume = () => {
    if (projects.loading) {
      return (
        <div className="animate-pulse flex space-x-4 mx-10 mb-8">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-400 rounded w-full"></div>
            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-400 rounded"></div>
              <div className="h-4 bg-gray-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      )
    } else if (projects.item.id && projects.item.id > 0) {
      return (
        <div className="w-8/12">
          <div className="grid grid-cols-3 py-10">
            <div align="left">
              <Progress percentage={projects.item.fulfillment || 0} />
            </div>
            <div align="left">
              <div className="py-3">
                <h6 className="font-semibold text-sm">Inicio del proyecto</h6>
                <h6 className="font-normal text-sm">{
                  projects.item.startDate ? (
                    new Date(parseInt(projects.item.startDate)).toLocaleDateString('es-MX', {
                      year: 'numeric', month: 'long', day: 'numeric'
                    })
                  ) : 'Fecha no establecida'
                }</h6>
              </div>
              <div className="py-3">
                <h6 className="font-semibold text-sm">Número de trabajadores</h6>
                <h6 className="font-normal text-sm">{projects.item.numWorkers || 0}</h6>
              </div>
            </div>
            <div align="left">
              <div className="py-3">
                <h6 className="font-semibold text-sm">Cantidad a operar</h6>
                <h6 className="font-normal text-sm">
                  {projects.item.amountToOperate ? (
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={projects.item.amountToOperate}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  ) : (
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    )}
                </h6>
              </div>
              <div className="py-3">
                <h6 className="font-semibold text-sm">Horas por trabajador</h6>
                <h6 className="font-normal text-sm">{projects.item.hoursPerWorker || 0 }</h6>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="min-h-screen justify-start bg-white py-12 sm:px-6 lg:px-8">
      <div align="center">
        <h2 className="mt-6 text-2xl font-bold font-poppins text-black">
          Resumen del proyecto
        </h2>
        {_render_resume()}
      </div>
      {/* <div className="w-full" align="center">
        <div className="w-4/12 bg-color-bg-payments rounded-lg py-6 pr-10">
          <div className="grid grid-cols-2 gap-4">
            <div className="grid grid-cols-6">
              <div className="col-start-2 col-span-4">
                <h6 className="font-semibold text-sm">Pr&oacute;ximo pago</h6>
                <h6 className="font-normal text-sm">1200</h6>
              </div>
              <div align="left">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.4573 5.01304L25.9873 8.54138L22.4573 5.01304ZM24.7273 1.90471L15.1823 11.4497C14.6891 11.9422 14.3528 12.5697 14.2157 13.253L13.334 17.6664L17.7473 16.783C18.4307 16.6464 19.0573 16.3114 19.5506 15.818L29.0957 6.27304C29.3825 5.98622 29.61 5.6457 29.7652 5.27094C29.9205 4.89618 30.0004 4.49451 30.0004 4.08888C30.0004 3.68324 29.9205 3.28158 29.7652 2.90682C29.61 2.53206 29.3825 2.19154 29.0957 1.90471C28.8088 1.61788 28.4683 1.39036 28.0935 1.23513C27.7188 1.0799 27.3171 1 26.9115 1C26.5058 1 26.1042 1.0799 25.7294 1.23513C25.3547 1.39036 25.0141 1.61788 24.7273 1.90471V1.90471Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M26.667 20.9997V25.9997C26.667 26.8837 26.3158 27.7316 25.6907 28.3567C25.0656 28.9818 24.2177 29.333 23.3337 29.333H5.00033C4.11627 29.333 3.26842 28.9818 2.6433 28.3567C2.01818 27.7316 1.66699 26.8837 1.66699 25.9997V7.66634C1.66699 6.78229 2.01818 5.93444 2.6433 5.30932C3.26842 4.6842 4.11627 4.33301 5.00033 4.33301H10.0003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div className="grid grid-cols-6">
              <div className="col-start-2 col-span-4">
                <h6 className="font-semibold text-sm">Fecha l&iacute;mite</h6>
                <h6 className="font-normal text-sm">1200</h6>
              </div>
              <div align="left">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.4573 5.01304L25.9873 8.54138L22.4573 5.01304ZM24.7273 1.90471L15.1823 11.4497C14.6891 11.9422 14.3528 12.5697 14.2157 13.253L13.334 17.6664L17.7473 16.783C18.4307 16.6464 19.0573 16.3114 19.5506 15.818L29.0957 6.27304C29.3825 5.98622 29.61 5.6457 29.7652 5.27094C29.9205 4.89618 30.0004 4.49451 30.0004 4.08888C30.0004 3.68324 29.9205 3.28158 29.7652 2.90682C29.61 2.53206 29.3825 2.19154 29.0957 1.90471C28.8088 1.61788 28.4683 1.39036 28.0935 1.23513C27.7188 1.0799 27.3171 1 26.9115 1C26.5058 1 26.1042 1.0799 25.7294 1.23513C25.3547 1.39036 25.0141 1.61788 24.7273 1.90471V1.90471Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M26.667 20.9997V25.9997C26.667 26.8837 26.3158 27.7316 25.6907 28.3567C25.0656 28.9818 24.2177 29.333 23.3337 29.333H5.00033C4.11627 29.333 3.26842 28.9818 2.6433 28.3567C2.01818 27.7316 1.66699 26.8837 1.66699 25.9997V7.66634C1.66699 6.78229 2.01818 5.93444 2.6433 5.30932C3.26842 4.6842 4.11627 4.33301 5.00033 4.33301H10.0003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div align="center">
        <h2 className="mt-6 text-2xl font-bold font-poppins text-black">
          Historial de pagos
        </h2>
        <div className="grid grid-cols-6">
          <div className="col-start-2 col-span-4 mx-40 mt-6">
            {renderPayments()}
          </div>
        </div>
        <div className="col-start-2 col-span-4 mx-40 mt-6">
          {submit ? (
            <div>
              <Loader
                type="ThreeDots"
                color="#50afbd"
                height={65}
                width={50}
                timeout={0}
              />
            </div>
          ) :
            (
              <input type="button" value="Registrar un nuevo pago" onClick={() => openModal()} className="cursor-pointer py-3 bg-color-btn text-white text-sm border-2 border-color-btn rounded-lg px-6 mr-2" />
            )
          }
        </div>
      </div>
      {_renderModal()}
      <input type="file" name="xml" id="xml" onChange={({ target: { files, name } }) => addfile(files, name)} ref={fileXmlInput} style={{ visibility: 'hidden' }} accept=".xml"></input>
      <input type="file" name="pdf" id="pdf" onChange={({ target: { files, name } }) => addfile(files, name)} ref={filePdfInput} style={{ visibility: 'hidden' }} accept=".pdf"></input>
    </div>
  )
}

export { HistorialPagos };