import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import apolloClient from './Apollo/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './_helpers';

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <Suspense fallback={<p>cargando...</p>}>
                <App />
            </Suspense>
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
