import React from 'react';
import Header from './Header';
import { ToastProvider } from 'react-toast-notifications';

const Layout = (props) => {
    const { children } = props;
    return (
        <div>
            <Header history={children.props.children.props.history}/>
            <ToastProvider autoDismiss={true} autoDismissTimeout={5000}>
                <main>{children}</main>
            </ToastProvider>
        </div>
    );
};

export { Layout };
