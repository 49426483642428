const projectsConstants = {
    CREATE_REQUEST: 'CREATE_PROJECT_REQUEST',
    CREATE_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_FAILURE: 'CREATE_PROJECT_FAILURE',
    
    UPDATE_REQUEST: 'UPDATE_PROJECT_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_PROJECT_FAILURE',

    GETALL_REQUEST: 'GETALL_PROJECT_REQUEST',
    GETALL_SUCCESS: 'GETALL_PROJECT_SUCCESS',
    GETALL_FAILURE: 'GETALL_PROJECT_FAILURE',

    GET_REQUEST: 'GET_PROJECT_REQUEST',
    GET_SUCCESS: 'GET_PROJECT_SUCCESS',
    GET_FAILURE: 'GET_PROJECT_FAILURE',

    

    CLEAR: 'PROJECT_CLEAR'
}
export { projectsConstants };