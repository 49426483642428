import { projectsConstants, filesConstants } from '../_constants';
import { projectsService, filesService } from '../_service';
import { alertActions } from "./alerts.actions";
import { Constantes } from "../_helpers";
export const proyectsActions = {
    create,
    update,
    getAll,
    get,
    addFavorite,
    removeFavorite,
    saveDocument,
    limpiar,
    getDocument
};

function create(dataRequest) {
    return dispatch => {
        dispatch(request());
        projectsService.create(dataRequest).then(
            project => {
                dispatch(alertActions.show('success', "Proyecto creado correctamente."));
                dispatch(success(project));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request() { return { type: projectsConstants.CREATE_SUCCESS } }
    function success(project) { return { type: projectsConstants.CREATE_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.CREATE_FAILURE, error } }
}
function update(dataRequest, id) {
    return dispatch => {
        dispatch(request(id));
        projectsService.update(dataRequest, id).then(
            project => {
                dispatch(success(project));
                dispatch(alertActions.show('success', "Proyecto actualizado correctamente."));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request(id) { return { type: projectsConstants.UPDATE_REQUEST, id } }
    function success(project) { return { type: projectsConstants.UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.UPDATE_FAILURE, error } }
}
function getAll(dataRequest, polling) {
    return dispatch => {
        if(!polling){
            // console.log("entro en polling", polling)
            dispatch(request());
        }
        projectsService.getAll(dataRequest)
        .subscribe(({ data, loading, error }) => {
            dispatch(success(data.projects));
          });
    };
    function request() { return { type: projectsConstants.GETALL_REQUEST } }
    function success(projects) { return { type: projectsConstants.GETALL_SUCCESS, projects } }
    // function failure(error) { return { type: projectsConstants.GETALL_FAILURE, error } }
}
function get(id) {
    return dispatch => {
        dispatch(request());
        projectsService.get(id).then(
            project => {
                dispatch(success(project));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: projectsConstants.GET_REQUEST } }
    function success(project) { return { type: projectsConstants.GET_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.GET_FAILURE, error } }
}
function addFavorite(userId, projectId) {
    return dispatch => {
        dispatch(request(projectId));
        projectsService.addFavorite(userId, projectId).then(
            project => {
                dispatch(success(project));
                dispatch(alertActions.show('success', "Se agrego a favoritos."));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request(id) { return { type: projectsConstants.UPDATE_REQUEST, id } }
    function success(project) { return { type: projectsConstants.UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.UPDATE_FAILURE, error } }
}
function removeFavorite(projectId) {
    return dispatch => {
        dispatch(request(projectId));
        projectsService.removeFavorite(projectId).then(
            project => {
                dispatch(success(project));
                dispatch(alertActions.show('success', "Se elimino a favoritos."));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request(id) { return { type: projectsConstants.UPDATE_REQUEST, id } }
    function success(project) { return { type: projectsConstants.UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.UPDATE_FAILURE, error } }
}
function saveDocument(file, projectId, campo, extra) {
    return dispatch => {
        dispatch(request(projectId));
        filesService.uploadFile(file).then(
            response => {
                if(response !== ""){
                    let dataRequest = {[campo]:response, status:Constantes.ESTATUS_ADD_DOCUMENT};
                    if(extra){
                        Object.assign(dataRequest, extra);
                    }
                    projectsService.update(dataRequest, projectId).then(
                        project => {
                            dispatch(success(project));
                            dispatch(alertActions.show('success', "Archivo cargado correctamente."));
                        },
                        error => {
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.show('error', error.toString()));
                        }
                    );
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.show('error', error.toString()));
            }
        );
    };
    function request(id) { return { type: projectsConstants.UPDATE_REQUEST, id } }
    function success(project) { return { type: projectsConstants.UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.UPDATE_FAILURE, error } }
}
function getDocument(url){
    return dispatch => {
        dispatch(request());
        filesService.downloadFile(url).then(
            file => {
                dispatch(success(file));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function request() { return { type: filesConstants.GET_REQUEST } }
    function success(file) { return { type: filesConstants.GET_SUCCESS, file } }
    function failure(error) { return { type: filesConstants.GET_FAILURE, error } }
}
function limpiar() {
    return dispatch => {
        dispatch(clear());
    };
    function clear() { return { type: projectsConstants.CLEAR } }
}