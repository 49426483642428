import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loader from 'react-loader-spinner';
import { LOGIN } from '../../Apollo/mutations/login';
import { userActions } from "../../_actions";
import { useToasts } from 'react-toast-notifications';

function Login({history}) {
    const { addToast } = useToasts();
    const alert = useSelector(state => state.alert);
    useEffect(() => {
        localStorage.removeItem('token');
    }, []);
    useEffect(() => {
        if(alert.message && alert.message !== ""){
            addToast(alert.message, { appearance: alert.severity });
        }
    }, []);
    const [waiting, setwaiting] = useState(false);
    const [errors, seterrors] = useState({
        status: false,
        message: 'Ha ocurrido un error',
    });
    const [login] = useMutation(LOGIN);
    const dispatch = useDispatch();
    const setLoggedIn = (session) => dispatch(userActions.loggedIn(session));
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required('Ingresa tu dirección de correo electrónico.')
            .email('Dirección de correo no válida'),
        password: Yup.string().required('Ingresa tu contraseña'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            try {
                setwaiting(true);
                const { data } = await login({
                    variables: {
                        credentials: {
                            email: formik.values.email,
                            password: formik.values.password,
                        },
                    },
                });
                console.log('::LOGIN::');
                const { token, user } = data.login;
                localStorage.setItem('token', token);
                setLoggedIn(user);
                var redirectAction = localStorage.getItem("redirectAction");
                if(redirectAction && redirectAction !== ""){
                    localStorage.removeItem("redirectAction");
                    history.push(redirectAction);
                }else{
                    setwaiting(false);
                    history.push(`/proyectos`);
                }
            } catch (err) {
                console.log('::ERROR::', err.message);
                if (err.message === 'invalid_user_or_password') {
                    seterrors({
                        status: true,
                        message: 'Su correo o contraseña son incorrectos',
                    });
                } else {
                    seterrors({ ...errors, status: true });
                }
                setwaiting(false);
            }
        },
    });
    return (
        <>
            <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-4xl font-bold font-poppins text-black">
                            Iniciar sesi&oacute;n
                        </h2>
                    </div>
                    {errors.status ? (
                        <div className="my-2 bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
                            <p className="text-sm">{errors.message}</p>
                        </div>
                    ) : null}
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={formik.handleSubmit}
                    >
                        <input type="hidden" name="remember" value="true" />
                        <div className="-space-y-px">
                            <div className="w-full my-9">
                                <label
                                    htmlFor="price"
                                    className="block text-lg font-normal text-black"
                                >
                                    Correo electr&oacute;nico
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="focus:ring-color-btn focus:border-color-btn block w-full pl-7 pr-12 sm:text-sm bg-gray-100 pb-4 pt-4 rounded-md"
                                        placeholder="nombre@ejemplo.com"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                        <span className="text-red-700 text-xs">
                                            {'* ' + formik.errors.email}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="w-full my-7">
                                <label
                                    htmlFor="price"
                                    className="block text-lg font-normal text-black"
                                >
                                    Contraseña
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="focus:ring-color-btn focus:border-color-btn block w-full pl-7 pr-12 sm:text-sm bg-gray-100 pb-4 pt-4 rounded-md"
                                        placeholder="Minimo 8 carateres"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.password &&
                                    formik.errors.password ? (
                                        <span className="text-red-700 text-xs">
                                            {'* ' + formik.errors.password}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="w-full" align="center">
                            <span className="text-color-btn cursor-pointer" onClick={()=>history.push('/recuperacion')}>¿Olvidaste tu contrase&ntilde;a?</span>
                        </div>
                        <div className="w-full" align="center">
                            {waiting ? (
                                <div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#50afbd"
                                        height={65}
                                        width={50}
                                        timeout={0}
                                    />
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    className="group relative flex justify-center border border-transparent text-sm font-semibold rounded-md text-white bg-color-btn p-4 px-6"
                                >
                                    Continuar
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export { Login };
