import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { proyectsActions, userActions } from "../../_actions";
import { useMutation } from '@apollo/client';
import { NOTIFICATION } from '../../Apollo/mutations/notification';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CardDocumento } from "./CardDocumento";
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Loader from 'react-loader-spinner';
import { NoFragmentCyclesRule } from 'graphql';
import { LEGAL_APPROVAL_QUESTION } from '../../Apollo/mutations/legalApproval';

const rejectedSchema = Yup.object({
    reason: Yup.string().required('Motivo requerido')
});

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AceptedModal({ modalState, closeModal, handleAcepted }) {
    const cancelButton = useRef(null);

    return (
        <Transition.Root show={modalState} as={Fragment}>
            <Dialog
                as='div'
                auto-reopen='true'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButton}
                onClose={closeModal}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                <div className='sm:flex item-center sm:items-center'>
                                    <div className='mt-3 text-center sm:mt-0'>
                                        <Dialog.Title as='h3' className='leading-tight text-4xl my-8 text-center leading-6 font-medium text-gray-900' align='center'>
                                            Validación de documentación
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <p className="text-sm text-gray-500">
                                                ¿Está seguro de validar la documentación?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-4 py-3 sm:px-6 sm:flex sm:justify-center'>
                                <button
                                    type='button'
                                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-color-btn text-base font-medium text-white hover:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => {
                                        closeModal(false);
                                        handleAcepted();
                                    }}
                                    ref={cancelButton}
                                >
                                    Aceptar
                                </button>
                                <button
                                    type='button'
                                    className='mt-3 w-full inline-flex justify-center border border-transparent rounded-md shadow-sm border border-gray-300 shadow-sm px-4 py-2 bg-laboralia-reverse text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => closeModal(false)}
                                    ref={cancelButton}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function RejectedModal({ modalState, closeModal, handleRejected }) {
    const cancelButtonRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: rejectedSchema,
        onSubmit: async (values, actions) => {
            if (values.reason) {
                await handleRejected(values.reason);
            }
        },
    });
    
    return (
        <Transition.Root show={modalState} as={Fragment}>
            <Dialog
                as='div'
                auto-reopen='true'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={closeModal}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <form className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' onSubmit={formik.handleSubmit}>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                <div className='sm:flex sm:items-start'>
                                    <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                                        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                                            Documentación no válida
                                        </Dialog.Title>
                                        <div className='mt-2'>
                                            <label htmlFor='reason' className='text-sm text-gray-500'>
                                                Escribe el motivo de la razón de la documentación no válida.
                                            </label>
                                            <textarea
                                                className='mt-4 text-sm shadow bg-white border rounded w-full py-2 px-3 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline'
                                                placeholder='Escriba su razón aqui'
                                                id='reason'
                                                name='reason'
                                                rows='4'
                                                cols='50'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.reason}
                                            />
                                            {
                                                formik.touched.reason &&
                                                    formik.errors.reason && (
                                                        <div className="my-2 bg-red-100 border-red-500 w-full text-red-700 p-4">
                                                            <p className="text-sm">
                                                                Debes ingresar un motivo
                                                            </p>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                <button
                                    type='submit'
                                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-color-btn text-base font-medium text-white hover:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
                                >
                                    Rechazar
                                </button>
                                <button
                                    type='button'
                                    className='mt-3 w-full inline-flex justify-center border border-transparent rounded-md shadow-sm border border-gray-300 shadow-sm px-4 py-2 bg-laboralia-reverse text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => closeModal(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function DocumentosJuridico(props) {
    const { id } = useParams();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { history } = props;
    const projects = useSelector(state => state.projects);
    const users = useSelector(state => state.users);
    const alert = useSelector(state => state.alert);
    const [aceptedModal, setAceptedModal] = useState(false);
    const [rejectedModal, setRejectedModal] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [cargado, setCargado] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [sendNotification] = useMutation(NOTIFICATION);
    const [navigation, setNavigation] = useState({
        items: [
            { id: 1, name: 'Proyecto', href: '#', current: true },
            { id: 2, name: 'Documentos del cliente', href: '#', current: false },
        ]
    });
    const [legalApproval] = useMutation(LEGAL_APPROVAL_QUESTION);
    
    useEffect(() => {
        if (alert.message && alert.message !== "") {
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    useEffect(() => {
        if (!projects.loading) {
            setCargado(false);
            setSubmit(false);
        }
    }, [projects]);
    useEffect(() => {
        if (id === null || id === undefined) {
            history.push(`/proyectos`);
        }
        dispatch(proyectsActions.get(parseInt(id)));
        dispatch(userActions.getAll());
    }, []);

    const updateStateSubmit = () => {
        setSubmit(true);
        setTimeout(() => {
            dispatch(proyectsActions.get(parseInt(id)));
            setSubmit(NoFragmentCyclesRule);
        }, 1500);
    }
    const render = () => {
        if (projects.loading) {
            return (
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (projects.item.id && projects.item.id > 0) {
                return (
                    <>
                        <div className="grid grid-cols-2" align="left">
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Titulo</h6>
                                    <h6 className="font-normal text-sm">{projects.item.titulo}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Descripci&oacute;n</h6>
                                    <h6 className="font-normal text-sm">{projects.item.description}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de cliente</h6>
                                    <h6 className="font-normal text-sm">{projects.item.billingKindClient[0].toUpperCase() + projects.item.billingKindClient.substr(1)}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de negocio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.business}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de servicio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.service}</h6>
                                </div>
                            </div>
                        </div>
                        <div align="center">
                            <h5 className="font-semibold pr-16 pb-4">Datos del cliente</h5>
                        </div>
                        <div className="grid grid-cols-2" align="left">
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Nombre</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.name + ' ' + projects.item.client.lastname}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Correo El&eacute;ctronico</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.email}</h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="py-3">
                                <h6 className="font-semibold text-sm">Alias: </h6>
                                <h6 className="font-normal text-sm">{projects.item.client.profile && projects.item.client.profile.alias || 'Sin alias'}</h6>
                            </div>
                        </div>
                        <div>
                            <div align="center">
                                <h5 className="font-semibold pr-16 pb-4">Datos para el contrato</h5>
                            </div>
                            <div>
                                {(projects.item.billingKindClient && projects.item.billingKindClient === 'fisica') ?
                                    (<div className="grid grid-cols-2" align="left">
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">RFC</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                            </div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">CURP</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.curp}</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Nombre completo</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.nombreCompleto}</h6>
                                            </div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Edad</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.edad}</h6>
                                            </div>
                                        </div>
                                    </div>)
                                    :
                                    ((projects.item.billingKindClient && projects.item.billingKindClient === 'moral') ?
                                        (<div className="grid grid-cols-2" align="left">
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">RFC</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Nombre del representante legal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.representanteLegal}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio fiscal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioFiscal}</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Razón social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.razonSocial}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioSocial}</h6>
                                                </div>
                                            </div>
                                        </div>)
                                        : (<div></div>))
                                }
                            </div>
                        </div>
                        {
                            projects.item.documentStatus.legalAreaApproval === false && (
                                <div>
                                    <div align="center">
                                        <h5 className="font-semibold pr-16 pb-4">Detalles de la validación de documentos</h5>
                                    </div>
                                    <div className="grid grid-cols-1" align="left">
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Motivo de documentación no válida</h6>
                                                <h6 className="font-normal text-sm">{projects.item.documentStatus.rejectionReasonLegalArea}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </>
                )
            }
        }
    }
    const changeNavigations = ({ target }) => {
        const name = target.getAttribute('name');
        var navigationAux = navigation;
        var nav = null;
        for (var i = 0; i <= navigationAux.items.length - 1; i++) {
            navigationAux.items[i].current = navigationAux.items[i].name === name ? true : false;
            if (nav === null) {
                nav = navigationAux.items[i].name === name ? navigationAux.items[i] : null;
            }
        }
        setNavigation({ ...navigation, 'items': navigationAux.items });
    }
    const render_docs = () => {
        const items = [];
        if (projects.item.id && projects.item.id > 0) {
            let selectNav = 0;
            for (var i = 0; i <= navigation.items.length - 1; i++) {
                if (navigation.items[i].current) {
                    selectNav = navigation.items[i].id;
                }
            }
            if (selectNav === 1) {
                if (disabledBtn && !cargado) {
                    let disableAux = false;
                    if (!projects.item.waitingForLegalArea) {
                        disableAux = true;
                    }
                    setCargado(true);
                    setDisabledBtn(disableAux);
                }
                let quote = {
                    name: 'Cotización',
                    column: 'quote',
                    descripcion: 'Máx 50 MB: PDF / PNG / JPG',
                    url: projects.item.quoteFileUrl,
                    campo: 'quoteFileUrl',
                    btn: false,
                    state: projects.item.documentStatus ? projects.item.documentStatus.quote : null,
                    projectId: parseInt(id),
                    extra: {
                        documentStatus: {
                            quote: 'uploaded'
                        }
                    },
                    redirect: 'quote'
                }
                let contract = {
                    name: 'Contrato',
                    column: 'contract',
                    descripcion: 'Prestación de servicios',
                    url: projects.item.contractFileUrl,
                    campo: 'contractFileUrl',
                    btn: false,
                    state: projects.item.documentStatus ? projects.item.documentStatus.contract : null,
                    projectId: parseInt(id),
                    extra: {
                        documentStatus: {
                            contract: 'uploaded'
                        }
                    },
                    redirect: 'contract'
                }
                let contractSigned = {
                    name: 'Contrato firmado',
                    column: 'signedContract',
                    descripcion: 'Prestación de servicios',
                    url: projects.item.contractsignedfileurl,
                    campo: 'contractsignedfileurl',
                    btn: true,
                    state: projects.item.documentStatus ? projects.item.documentStatus.signedContract : null,
                    projectId: parseInt(id)
                }
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={quote} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Quote"} />)
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={contract} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Contract"} />)
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={contractSigned} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Contract-Signed"} />)
            } else {
                if (projects.item.billingKindClient === 'moral') {
                    let quote = {
                        name: 'Acta constitutiva',
                        column: 'actaConstitutivaStatus',
                        descripcion: '',
                        url: projects.item.client.profile.actaConstitutivaFileUrl,
                        campo: 'actaConstitutivaFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.actaConstitutivaStatus ? projects.item.client.profile.documentStatus.actaConstitutivaStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let ifeRepLegalFileUrl = {
                        name: 'IFE/INE representante legal',
                        column: 'ifeRepLegalStatus',
                        descripcion: '',
                        url: projects.item.client.profile.ifeRepLegalFileUrl,
                        campo: 'ifeRepLegalFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.ifeRepLegalStatus ? projects.item.client.profile.documentStatus.ifeRepLegalStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let cifFileUrl = {
                        name: 'CIF',
                        column: 'cifStatus',
                        descripcion: '',
                        url: projects.item.client.profile.cifFileUrl,
                        campo: 'cifFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.cifFileStatus ? projects.item.client.profile.documentStatus.cifFileStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={quote} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-actaConstitutivaFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={ifeRepLegalFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-ifeRepLegalFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={cifFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-cifFileUrl"} />)
                } else if (projects.item.billingKindClient === 'fisica') {
                    let actaNacimientoFileUrl = {
                        name: 'Acta de nacimiento',
                        column: 'actaNacimientoStatus',
                        descripcion: '',
                        url: projects.item.client.profile.actaNacimientoFileUrl,
                        campo: 'actaNacimientoFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.actaNacimientoStatus ? projects.item.client.profile.documentStatus.actaNacimientoStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let comprobanteDomicilioFileUrl = {
                        name: 'Comprobante de domicilio',
                        column: 'comprobanteDomicilioStatus',
                        descripcion: '',
                        url: projects.item.client.profile.comprobanteDomicilioFileUrl,
                        campo: 'comprobanteDomicilioFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.comprobanteDomicilioStatus ? projects.item.client.profile.documentStatus.comprobanteDomicilioStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let ifePersonaFisicaFileUrl = {
                        name: 'IFE/INE persona fisica',
                        column: 'ifePersonaFisicaStatus',
                        descripcion: '',
                        url: projects.item.client.profile.ifePersonaFisicaFileUrl,
                        campo: 'ifePersonaFisicaFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.ifePersonaFisicaStatus ? projects.item.client.profile.documentStatus.ifePersonaFisicaStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={actaNacimientoFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-actaNacimientoFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={ifePersonaFisicaFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-ifePersonaFisicaFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={comprobanteDomicilioFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-comprobanteDomicilioFileUrl"} />)
                }
            }
        }
        return (items);
    }

    const handleAcepted = async () => {
        const { data: { updateProject: { assignedTo } } } = await legalApproval({
            variables: {
                id: parseInt(projects.item.id),
                input: {
                    waitingForLegalArea: false,
                    documentStatus: {
                        legalAreaApproval: true
                    }
                }
            }
        });

        // if(data) {
        if(assignedTo) {
            const email = users && users.items && users.items.filter(user => parseInt(user.id) === parseInt(assignedTo))[0].email;
            const projectId = projects && projects.item && projects.item.id;
            const { data: dataN } = await sendNotification({
                variables: {
                    input: {
                        email: email,
                        title: "Validacion de documentación",
                        movement: `Se ha validado la documentación #${parseInt(projectId)}`,
                        redirect: `documentos/${parseInt(projectId)}`,
                        subject: `Validación del proyecto #${parseInt(projectId)}`
                    }
                }
            });

            if(dataN) {
                addToast('Se ha validado la documentación', { appearance: 'success', autoDismiss: true });
                history.push('/');   
            }
        }
    }

    const handleRejected = async (reason) => {
        const { data: { updateProject: { assignedTo } } } = await legalApproval({
            variables: {
                id: parseInt(projects.item.id),
                input: {
                    waitingForLegalArea: false,
                    documentStatus: {
                        legalAreaApproval: false,
                        rejectionReasonLegalArea: reason
                    }
                }
            }
        });

        if(assignedTo) {
            const email = users && users.items && users.items.filter(user => parseInt(user.id) === parseInt(assignedTo))[0].email;
            const projectId = projects && projects.item && projects.item.id;
            const { data: dataN } = await sendNotification({
                variables: {
                    input: {
                        email: email,
                        title: "Validacion no válida de documentación",
                        movement: `No se ha validado la documentación #${parseInt(projectId)}`,
                        redirect: `documentos/${parseInt(projectId)}`,
                        subject: `No se ha validado la documentación del proyecto #${parseInt(projectId)}`
                    }
                }
            });

            if(dataN) {
                addToast('No se ha válidado la documentación', { appearance: 'info', autoDismiss: true });
                history.push('/');   
            }

        }
    }

    return (
        <div className="min-h-screen justify-start bg-white py-12 sm:px-6 lg:px-8">
            <div align="center">
                <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                    Documentos Juridicos
                </h2>
            </div>
            <div align="center">
                <div className="rounded-2xl shadow-xl sm:w-5/12 sm:p-4 h-auto mt-10" align="left">
                    <div>
                        <h6 className="font-bold text-base">Informaci&oacute;n del proyecto</h6>
                    </div>
                    {render()}
                </div>
                <div className="container mt-8" >
                    <div className="sm:block">
                        <div className="w-1/6 space-x-12" align="center">
                            <div className="grid grid-cols-2">
                                {navigation.items.map((item) => (
                                    <h5
                                        key={item.name}
                                        name={item.name}
                                        className={classNames(
                                            item.current ? ' cursor-pointer border-b-4 border-color-select rounded-none' : 'cursor-pointer text-gray-500',
                                            'px-3 pt-2 rounded-md text-sm font-semibold flex items-center justify-center'
                                        )}
                                        onClick={changeNavigations}
                                    >
                                        {item.name}
                                    </h5>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:w-6/12 sm:p-4 h-auto mt-2">
                    <div className="grid grid-cols-2">
                        {render_docs()}
                    </div>
                </div>
                <div className="w-full" align="center">
                    {submit ? (
                        <div>
                            <Loader
                                type="ThreeDots"
                                color="#50afbd"
                                height={65}
                                width={50}
                                timeout={0}
                            />
                        </div>
                    ) : (
                        <>
                            {
                                projects.item.waitingForLegalArea && (
                                    <>
                                        <input
                                            type="button"
                                            disabled={disabledBtn} onClick={() => setAceptedModal(!aceptedModal)}
                                            className={
                                                classNames(!disabledBtn ? "bg-color-btn cursor-pointer hover:shadow-none" : "bg-gray-300", 'mx-2 p-2 shadow-xl rounded text-white')
                                            }
                                            value="Documentación válida"
                                        />
                                        <input
                                            type="button"
                                            disabled={disabledBtn} onClick={() => setRejectedModal(!rejectedModal)}
                                            className={
                                                classNames(!disabledBtn ? "bg-color-btn cursor-pointer hover:shadow-none" : "bg-gray-300", 'mx-2 p-2 shadow-xl rounded text-white')
                                            }
                                            value="Documentación no válida"
                                        />
                                    </>
                                )
                            }
                            <AceptedModal modalState={aceptedModal} closeModal={setAceptedModal} handleAcepted={handleAcepted} />
                            <RejectedModal modalState={rejectedModal} closeModal={setRejectedModal} handleRejected={handleRejected} />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export { DocumentosJuridico };