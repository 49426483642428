import React, { useState, useEffect } from 'react';
import icon from "../../_helpers/svgs/office.svg";

function CardAssigment(props) {
    const { item, setOpen, setProject, executives } = props;
    const [ executiveName, setExecutiveName ] = useState({});
    const [dataDate, setDataDate] = useState({
        date: '',
        time: ''
    });

    useEffect(() => {
        if (item.createdAt) {
            const newDate = new Date(item.createdAt);
            setDataDate({
            date: newDate.toLocaleDateString('es-MX'),
            time: `${newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours() }:${newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()}`
            });
        }
    }, [item]);

    useEffect(() => {
        if (executives) {
            setExecutiveName(executives.users.filter(executive => parseInt(executive.id) === parseInt(item.assignedTo))[0])
        }
    }, [item])

    const handleClick = (project) => {
        setProject(project);
        setOpen(true);
    }

    return (
        <>
            <div className="rounded-2xl shadow-lg sm:w-10/12 sm:p-4 h-auto flex flex-col justify-between">
                <div className="grid grid-cols-3">
                    <div>
                        <img
                            src={icon}
                            alt="icon" />
                    </div>
                    <div className="col-span-2" align="right">
                        {
                            item.assigned && executiveName && (
                                <h5 className='font-bold text-sm text-green-500'>{`${executiveName.name} ${executiveName.lastname}`}</h5>
                            )
                        }
                    </div>
                </div>
                <div>
                    <h5 className="font-bold font-normal py-2">{ `${item.id} - ${item.title !== null && item.title !== "" ? item.title : "Sin Nombre" }`}</h5>
                    <h5 className="font-normal text-sm pb-2">{item.business}</h5>
                    <h5 className="font-normal text-sm">{item.client.name + ' ' + item.client.lastname + ' ' + `${dataDate.date} - ${dataDate.time}`}</h5>
                </div>
                <div className="flex items-center justify-center pt-6">
                    <div className="pt-2 cursor-pointer" onClick={() => handleClick(item)}>
                        <h5 className="text-color-btn font-bold">
                            { !item.assigned ? 'Asignar proyecto' : 'Reasignar proyecto' }
                        </h5>
                    </div>
                </div>
            </div>
        </>
    )
}
export { CardAssigment };