import { gql } from '@apollo/client';

export const SWITCH_INIT_QUERY = gql`
    query project($id: Int!){
        project(id: $id) {
            initProjectValidation
            waitingForLegalArea
            documentStatus {
                legalAreaApproval
                rejectionReasonLegalArea
            }
        }
    }
`;
