import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { proyectsActions, notificationsActions, userActions } from "../../_actions";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Constantes } from "../../_helpers";
import { useToasts } from 'react-toast-notifications';
import { getDateFormat } from '../../_helpers';
import { registerLocale } from 'react-datepicker';
import mx from "date-fns/locale/es";
// import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", mx);

const UserEditModal = ({ open, setOpen, userId }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null);
    const [user, setUser] = useState({
        alias: '',
    });

    const closeModal = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({...user, [name]: value });
    }

    const saveEdit = () => {
        dispatch(userActions.update({ profile: { alias: user.alias }} , parseInt(userId)));
        setTimeout(() => {
            closeModal();
            dispatch(proyectsActions.get(parseInt(id)));
        }, 500);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={() => closeModal(false)}

            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:items-start">
                                    <div className="my-3 text-center sm:mt-0 sm:text-left" align="center">
                                        <Dialog.Title as="h3" align="center">
                                            <div>
                                                <div align="right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={closeModal}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                                <div className="text-3xl leading-6 font-semibold text-gray-900 mx-20 pt-10">
                                                    <div>Editar</div>
                                                </div>
                                            </div>
                                        </Dialog.Title>
                                        <div className="w-full mt-6">
                                            <input type="text" value={user.alias} onChange={handleChange} name="alias" id="alias" placeholder="Alias" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 pb-3 mb-8" >
                                <div className="w-full px-1" align="center">
                                    <button
                                        type="button"
                                        className="text-white bg-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                        onClick={() => {
                                            saveEdit();
                                        }}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function Informacion(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState(false);
    const cancelButtonRef = useRef(null);
    const { id } = useParams();
    const { history } = props;
    const [typeRender, setTypeRender] = useState(1);
    const projects = useSelector(state => state.projects);
    const alert = useSelector(state => state.alert);
    const [inputs, setInputs] = useState({
        title: '',
        description: ''
    });
    
    useEffect(() => {
        if(alert.message && alert.message!==""){
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    useEffect(() => {
        if (id === null || id === undefined) {
            history.push(`/proyectos`);
        }
        dispatch(proyectsActions.get(parseInt(id)));
    }, []);
    const openModal = () => {
        let objAux = {
            title: projects.item.title,
            description: projects.item.description
        };
        setInputs(objAux);
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({...inputs, [name]:value});
    }

    const confirmarProyecto = () => {
        let dataRequest ={
            email: projects.item.client.email,
            title: projects.item.title?projects.item.title:'Actualización del proyecto',
            movement: 'Se confirmo la recepción del proyecto.',
            redirect:`details/${projects.item.id}?redirect=true`,
            subject: `Confirmación de recibido del proyecto #${projects.item.id}`
        };
        dispatch(notificationsActions.sendNotificationClient(dataRequest));
        dataRequest.email ='';
        dataRequest.redirect =`documentos/${projects.item.id}`;
        dataRequest.subject = `Confirmación del proyecto #${projects.item.id}`;
        dispatch(notificationsActions.sendNotificationOperator(dataRequest, projects.item.favorite));
        dispatch(proyectsActions.update({ section: Constantes.ESTATUS_NO_INICIADO }, id));
    }
    const saveEdit = () => {
        dispatch(proyectsActions.update( inputs , id));
        setTimeout(() => {
            setTypeRender(1);
            closeModal();
        }, 500);
    }
    const _renderModal = () => {
        let items = [];
        if (typeRender === 1) {
            items.push(
                <>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="my-3 text-center sm:mt-0 sm:text-left" align="center">
                                <Dialog.Title as="h3" align="center">
                                    <div>
                                        <div align="right">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={closeModal}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div className="text-3xl leading-6 font-semibold text-gray-900 mx-20 pt-10">
                                            <div>Proyecto</div>
                                            <div className="mt-2">Recibido</div>
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="mt-6 mx-10">
                                    <span className="text-sm font-normal text-gray-600" align="center">
                                        El proyecto ha sido recibido y lo encontraras ahora en la pestaña de "Proyectos no iniciados".
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 pb-3 mb-8" >
                        <div className="w-full px-1" align="center">
                            <button
                                type="button"
                                className="text-white bg-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                onClick={() => {
                                    confirmarProyecto();
                                    history.push('/proyectos');
                                    setOpen(false);
                                }}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeRender === 2) {
            items.push(
                <>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:items-start">
                            <div className="my-3 text-center sm:mt-0 sm:text-left" align="center">
                                <Dialog.Title as="h3" align="center">
                                    <div>
                                        <div align="right">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={closeModal}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div className="text-3xl leading-6 font-semibold text-gray-900 mx-20 pt-10">
                                            <div>Editar</div>
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="w-full mt-6">
                                    <input type="text" value={inputs.title} onChange={handleChange} name="title" id="title" placeholder="Titulo" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline"/>
                                    <input type="text" value={inputs.description} onChange={handleChange} name="description" id="description" placeholder="Descripción" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 pb-3 mb-8" >
                        <div className="w-full px-1" align="center">
                            <button
                                type="button"
                                className="text-white bg-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                onClick={() => {
                                    saveEdit();
                                }}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </>
            )
        }
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={closeModal}

                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                  </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                                {items}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const _render = () => {
        if (projects.loading) {
            return (
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (projects.item.id && projects.item.id > 0) {
                return (
                    <>
                        <div className="grid grid-cols-4" align="left">
                            <div className="col-start-2">
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Titulo</h6>
                                    <h6 className="font-normal text-sm">{projects.item.title}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Descripci&oacute;n</h6>
                                    <h6 className="font-normal text-sm">{projects.item.description}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de cliente</h6>
                                    <h6 className="font-normal text-sm">{projects.item.billingKindClient[0].toUpperCase() + projects.item.billingKindClient.substr(1)}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de negocio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.business}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de servicio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.service}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Fecha de creaci&oacute;n</h6>
                                    <h6 className="font-normal text-sm">{getDateFormat(projects.item.createdAt)}</h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="font-semibold pr-16 pb-4">Datos del cliente</h5>
                        </div>
                        <div className="grid grid-cols-4 pt-6" align="right">
                            <svg xmlns="http://www.w3.org/2000/svg" className="col-start-3 h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" 
                            onClick={()=>{
                                setUserEdit(true);
                            }}>
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="grid grid-cols-4" align="left">
                            <div className="col-start-2">
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Nombre</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.name + ' ' + projects.item.client.lastname}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Alias: </h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.profile && projects.item.client.profile.alias || 'Sin alias'}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Correo El&eacute;ctronico</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.email}</h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="font-semibold pr-16 pb-4">Datos para el contrato</h5>
                            <div>
                                {(projects.item.billingKindClient && projects.item.billingKindClient === 'fisica')?
                                    (<div className="grid grid-cols-4" align="left">
                                        <div className="col-start-2">
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">RFC</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">CURP</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.curp}</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Nombre completo</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.nombreCompleto}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Edad</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.edad}</h6>
                                                </div>
                                        </div>
                                    </div>)
                                    :
                                    ((projects.item.billingKindClient && projects.item.billingKindClient === 'moral')?
                                    (<div className="grid grid-cols-4" align="left">
                                        <div className="col-start-2">
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">RFC</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Nombre del representante legal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.representanteLegal}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio fiscal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioFiscal}</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Razón social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.razonSocial}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioSocial}</h6>
                                                </div>
                                        </div>
                                    </div>)
                                    :(<div></div>))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        }
    }
    const _renderBtn = () => {
        if (projects.item.id && projects.item.id > 0) {
            return (
                <div className="w-full" align="center">
                    <div className="px-1">
                        <button
                            type="button"
                            className="p-4 text-white bg-color-btn border rounded-lg py-2 text-sm"
                            onClick={() => {
                                confirmarProyecto();
                                setTypeRender(1);
                                openModal();
                            }}
                        >
                            Confirmar recibido
                                </button>
                    </div>
                </div>
            )
        }
    }
    return (
        <div className="min-h-screen justify-start bg-white py-12 sm:px-6 lg:px-8">
            <div className="my-10  mx-32 border border-gray-300" align="center">
                <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                    Informaci&oacute;n proporcionada por el cliente
                </h2>
                <div className="grid grid-cols-4 pt-6" align="right">
                    <svg xmlns="http://www.w3.org/2000/svg" className="col-start-3 h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" 
                    onClick={()=>{
                        setTypeRender(2);
                        openModal();
                    }}>
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className="mt-4">
                    {_render()}
                </div>
            </div>
            <div className="my-10  mx-32">
                {_renderBtn()}
            </div>
            {_renderModal()}
            { projects.item.client && <UserEditModal open={userEdit} setOpen={setUserEdit} userId={projects.item.client.id} /> }
        </div>
    )
}
export { Informacion };