import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation login($credentials: Credentials!) {
        login(credentials: $credentials) {
            token
            user {
                id
                name
                lastname
                email
                avatar
                enabled
                verified
                roleId
                role
                roleSlug
                permissions {
                    title
                    slug
                }
                profile{
                    alias
                    clientFilter
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioFiscal
                    domicilioSocial
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    documentStatus{
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
            }
        }
    }
`;
