import { filesConstants } from '../_constants';
let items = [];
let item = {};
const initialState = { loading: true, items, item };

export function files(state = initialState, action) {
	switch (action.type) {
		case filesConstants.GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case filesConstants.GET_SUCCESS:
			return {
				...state,
				loading:false,
				item: ((action.file!==null)?action.file:{})
			};
		case filesConstants.GET_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		default:
			return state
	}
}