const paymentsConstants = {
    CREATE_REQUEST: 'CREATE_PAYMENTS_REQUEST',
    CREATE_SUCCESS: 'CREATE_PAYMENTS_SUCCESS',
    CREATE_FAILURE: 'CREATE_PAYMENTS_FAILURE',
    
    UPDATE_REQUEST: 'UPDATE_PAYMENTS_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_PAYMENTS_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_PAYMENTS_FAILURE',

    GETALL_REQUEST: 'GETALL_PAYMENTS_REQUEST',
    GETALL_SUCCESS: 'GETALL_PAYMENTS_SUCCESS',
    GETALL_FAILURE: 'GETALL_PAYMENTS_FAILURE',

    GET_REQUEST: 'GET_PAYMENTS_REQUEST',
    GET_SUCCESS: 'GET_PAYMENTS_SUCCESS',
    GET_FAILURE: 'GET_PAYMENTS_FAILURE',
    
    DELETE_REQUEST: 'PAYMENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'PAYMENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'PAYMENT_DELETE_FAILURE',    
}
export { paymentsConstants };