import { projectsConstants } from '../_constants';
let items = [];
let item = {};
const initialState = { loading: true, items, item };

export function projects(state = initialState, action) {
	switch (action.type) {
		case projectsConstants.GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case projectsConstants.GET_SUCCESS:
			return {
				...state,
				loading:false,
				item: ((action.project!==null)?action.project:{})
			};
		case projectsConstants.GET_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case projectsConstants.GETALL_REQUEST:
			return {
				...state,
				loading: true
			};
		case projectsConstants.GETALL_SUCCESS:
			return {
				...state,
				loading:false,
				items: ((action.projects!==null)?action.projects:[]),
			};
		case projectsConstants.GETALL_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case projectsConstants.CREATE_REQUEST:
			return {
				...state,
				loading: true
			};
		case projectsConstants.CREATE_SUCCESS:
			return {
				...state,
				loading:false,
				item: action.project,
				items: state.items.concat(action.project)
			};
		case projectsConstants.CREATE_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case projectsConstants.UPDATE_REQUEST:
			return {
				...state,
				item: { ...item, loading: true },
				loading:true,
			};
		case projectsConstants.UPDATE_SUCCESS:
			return {
				...state,
				item: action.project,
				items: state.items.map(item => {
					if(item.id === action.project.id){
						item = action.project;
					}
					return item;
				}),
				loading:false,
			};
		case projectsConstants.UPDATE_FAILURE:
			return {
				...state,
				item: { ...item, loading: false },
				items: state.items.map(item =>
					item.id === action.id
					? { ...item, loading: false }
					: item
				),
				error: action.error
			};
		case projectsConstants.CLEAR:
			var stateInit = initialState;
			stateInit.loading = false;
			return stateInit;
		default:
			return state
	}
}