import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { proyectsActions, notificationsActions, userActions } from "../../_actions";
import { Constantes } from "../../_helpers"
import { CardDocumento } from "./CardDocumento";
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Loader from 'react-loader-spinner';
import { NoFragmentCyclesRule } from 'graphql';
import DatePicker from 'react-datepicker';
import { SWITCH_INIT } from '../../Apollo/mutations/switchInit';
import { LEGAL_APPROVAL } from '../../Apollo/mutations/legalApproval';
import { SWITCH_INIT_QUERY } from '../../Apollo/queries/switchInit';
import { useLazyQuery, useMutation } from '@apollo/client';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function InitValidation({ modalState, closeModal, switchProject, result }) {
    const { id } = useParams();
    const { addToast } = useToasts();
    const cancelButton = useRef(null);

    const handleSwitch = () => {
        switchProject({ variables: { id: parseInt(id) } })
    }

    useEffect(() => {
        if(result) {
            closeModal(false);
            addToast("Cambio de permisos del proyecto", {
                appearance: 'success',
                autoDismiss: true,
            });
            setTimeout(() => {
                window.location.reload();
            }, 500)
        }
    }, [result])

    return (
        <Transition.Root show={modalState} as={Fragment}>
            <Dialog
                as='div'
                auto-reopen='true'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButton}
                onClose={closeModal}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <form className='inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                <div className='sm:flex item-center sm:items-center'>
                                    <div className='mt-3 text-center sm:mt-0'>
                                        <Dialog.Title as='h3' className='text-4xl text-center leading-6 font-medium text-gray-900' align='center'>
                                            ¡Atención!
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <p className="text-sm text-gray-500">
                                                Estás por habiitar la posibilidad de iniciar un proyecto sin la documentación completa.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-4 py-3 sm:px-6 sm:flex sm:justify-center'>
                                <button
                                    type='button'
                                    className='mt-3 w-full border-2 border-color-btn inline-flex text-color-btn justify-center  rounded-md shadow-sm shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => closeModal(false)}
                                    ref={cancelButton}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type='button'
                                    className='mt-3 w-full bg-color-btn inline-flex text-white justify-center rounded-md shadow-sm shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={handleSwitch}
                                    ref={cancelButton}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function CheckModal({ modalState, closeModal, setDisabledBtn, legalUsers }) {
    const { id } = useParams();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const alert = useSelector((state) => state.alert);
    const projects = useSelector(state => state.projects);
    const cancelButton = useRef(null);

    const handleValidation = () => {
        let legals = '';
        
        legalUsers.map((legal, index) => {
            if (index === legalUsers.length) {
                legals += `${legal.email}`;
            } else {
                legals += `${legal.email},`;
            }
        })

        let dataRequest = {
            email: legals,
            title: 'Validación de documentos del proyecto',
            movement: 'Petición de validación de documentación',
            redirect: `documentos/${projects.item.id}`,
            subject: `Validación de documentos en el proyecto #${projects.item.id}`
        };

        dispatch(proyectsActions.update({ waitingForLegalArea: true }, parseInt(id)));
        dispatch(notificationsActions.sendNotificationOperatorWithoutFav(dataRequest));
    }

    useEffect(() => {
        if(Object.keys(alert).length > 0) {
            closeModal(false);
            addToast(alert.message, {
                appearance: alert.severity,
                autoDismiss: true
            });

            setDisabledBtn(true)
        }
    }, [alert])

    return (
        <Transition.Root show={modalState} as={Fragment}>
            <Dialog
                as='div'
                auto-reopen='true'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButton}
                onClose={closeModal}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                <div className='sm:flex item-center sm:items-center'>
                                    <div className='mt-3 text-center sm:mt-0'>
                                        <Dialog.Title as='h3' className='text-4xl my-8 text-center leading-6 font-medium text-gray-900' align='center'>
                                            ¡Enviado a revisión!
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <p className="text-sm text-gray-500">
                                                Se enviara la documentación a revisión al área juridica.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-4 py-3 sm:px-6 sm:flex sm:justify-center'>
                                <button
                                    type='button'
                                    className='my-4 w-full bg-color-btn inline-flex text-white justify-center rounded-md shadow-sm shadow-sm px-12 py-2 text-base font-medium focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => {
                                        closeModal(false);
                                        handleValidation();
                                    }}
                                    ref={cancelButton}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function Documentos(props) {
    const { id } = useParams();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication);
    const [ switchProject, { data: result }] = useMutation(SWITCH_INIT);
    const [getValidation, { data }] = useLazyQuery(SWITCH_INIT_QUERY, { pollInterval: 1000 });
    const { history } = props;
    const projects = useSelector(state => state.projects);
    const users = useSelector(state => state.users);
    const alert = useSelector(state => state.alert);
    const [open, setOpen] = useState(false);
    const [legalUsers, setLegalUsers] = useState([]);
    const [openInitValidation, setOpenInitValidation] = useState(false);
    const [openSwitch, setopenSwitch] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);
    const cancelButtonRef = useRef(null);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [cargado, setCargado] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [navigation, setNavigation] = useState({
        items: [
            { id: 1, name: 'Proyecto', href: '#', current: true },
            { id: 2, name: 'Documentos del cliente', href: '#', current: false },
        ]
    });
    const [projectData, setProjectData] = useState({
        amountToOperate: '',
        numWorkers: '',
        hoursPerWorker: '',
        startDate: null,
        // fulfillment: 0
    });
    useEffect(() => {
        getValidation({
            variables: {
                id: parseInt(id)
            }
        });

        data && setOpenInitValidation(data.project.initProjectValidation);
    }, [data]);
    useEffect(() => {
        if (alert.message && alert.message !== "") {
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    useEffect(() => {
        if (!projects.loading) {
            setCargado(false);
            setSubmit(false);
        }
    }, [projects]);
    useEffect(() => {
        if (id === null || id === undefined) {
            history.push(`/proyectos`);
        }
        dispatch(proyectsActions.get(parseInt(id)));
        dispatch(userActions.getAll());
    }, []);

    
    useEffect(() => {
        if (users.items.length > 0) {
            setLegalUsers(users.items.filter(user => user.roleSlug === 'legal'))
        }
    }, [users])

    const handleDateProject = (e) => {
        const { name, value } = e.target;
        setProjectData({...projectData, [name]: parseInt(value) });
    }
    const saveProjectData = () => {
        if(projectData.amountToOperate!=="" && 
        // projectData.fulfillment>0 && 
        projectData.hoursPerWorker!=='' && projectData.numWorkers!=='' && projectData.startDate!==''){
            dispatch(proyectsActions.update(projectData , id));
            setTimeout(() => {
                updateSection();
            }, 500);
        }else{
            addToast("Los datos Monto a operar, número de trabajadores, horas por trabajador y fecha de inicio son obligatorios", { appearance: 'error' });
        }
    }
    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    const updateStateSubmit = () => {
        setSubmit(true);
        setTimeout(() => {
            dispatch(proyectsActions.get(parseInt(id)));
            setSubmit(NoFragmentCyclesRule);
        }, 1500);
    }
    const updateSection = () => {
        setSubmit(true);
        let dataRequest = {
            email: projects.item.client.email,
            title: projects.item.title ? projects.item.title : 'Actualización del proyecto',
            movement: 'Se inicio el proyecto.',
            redirect: `details/${projects.item.id}?redirect=true`,
            subject: `Actualización en el proyecto #${projects.item.id}`
        };
        dispatch(notificationsActions.sendNotificationClient(dataRequest));
        dataRequest.email = '';
        dataRequest.redirect = `documentos/${projects.item.id}`;
        dataRequest.subject = `Actualización en el proyecto #${projects.item.id}`;
        dispatch(notificationsActions.sendNotificationOperator(dataRequest, projects.item.favorite));
        dispatch(proyectsActions.update({ section: Constantes.ESTATUS_EN_PROCESO }, parseInt(id)));
        history.push(`/proyectos`);
    }
    const render = () => {
        if (projects.loading) {
            return (
                <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-full"></div>
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (projects.item.id && projects.item.id > 0) {
                return (
                    <>
                        <div className="grid grid-cols-2" align="left">
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Titulo</h6>
                                    <h6 className="font-normal text-sm">{projects.item.titulo}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Descripci&oacute;n</h6>
                                    <h6 className="font-normal text-sm">{projects.item.description}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de cliente</h6>
                                    <h6 className="font-normal text-sm">{projects.item.billingKindClient[0].toUpperCase() + projects.item.billingKindClient.substr(1)}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de negocio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.business}</h6>
                                </div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Tipo de servicio</h6>
                                    <h6 className="font-normal text-sm">{projects.item.service}</h6>
                                </div>
                            </div>
                        </div>
                        <div align="left">
                            <h5 className="font-semibold pr-16 pb-4">Datos del cliente</h5>
                        </div>
                        <div className="grid grid-cols-2" align="left">
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Nombre</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.name + ' ' + projects.item.client.lastname}</h6>
                                </div>
                            </div>
                            <div>
                                <div className="py-3">
                                    <h6 className="font-semibold text-sm">Correo El&eacute;ctronico</h6>
                                    <h6 className="font-normal text-sm">{projects.item.client.email}</h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="py-3">
                                <h6 className="font-semibold text-sm">Alias: </h6>
                                <h6 className="font-normal text-sm">{projects.item.client.profile && projects.item.client.profile.alias || 'Sin alias'}</h6>
                            </div>
                        </div>
                        
                        <div>
                            <div align="left">
                                <h5 className="font-semibold pr-16 pb-4">Datos para el contrato</h5>
                            </div>
                            <div>
                                {(projects.item.billingKindClient && projects.item.billingKindClient === 'fisica') ?
                                    (<div className="grid grid-cols-2" align="left">
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">RFC</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                            </div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">CURP</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.curp}</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Nombre completo</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.nombreCompleto}</h6>
                                            </div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Edad</h6>
                                                <h6 className="font-normal text-sm">{projects.item.client.profile.edad}</h6>
                                            </div>
                                        </div>
                                    </div>)
                                    :
                                    ((projects.item.billingKindClient && projects.item.billingKindClient === 'moral') ?
                                        (<div className="grid grid-cols-2" align="left">
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">RFC</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.rfc}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Nombre del representante legal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.representanteLegal}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio fiscal</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioFiscal}</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Razón social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.razonSocial}</h6>
                                                </div>
                                                <div className="py-3">
                                                    <h6 className="font-semibold text-sm">Domicilio social</h6>
                                                    <h6 className="font-normal text-sm">{projects.item.client.profile.domicilioSocial}</h6>
                                                </div>
                                            </div>
                                        </div>)
                                        : (<div></div>))
                                }
                            </div>
                        </div>
                        {
                            projects.item.documentStatus.legalAreaApproval === false && (
                                <div>
                                    <div align="center">
                                        <h5 className="font-semibold pr-16 pb-4">Detalles de la validación de documentos</h5>
                                    </div>
                                    <div className="grid grid-cols-1" align="left">
                                        <div>
                                            <div className="py-3">
                                                <h6 className="font-semibold text-sm">Motivo de documentación no válida</h6>
                                                <h6 className="font-normal text-sm">{projects.item.documentStatus.rejectionReasonLegalArea}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        }
    }
    const changeNavigations = ({ target }) => {
        const name = target.getAttribute('name');
        var navigationAux = navigation;
        var nav = null;
        for (var i = 0; i <= navigationAux.items.length - 1; i++) {
            navigationAux.items[i].current = navigationAux.items[i].name === name ? true : false;
            if (nav === null) {
                nav = navigationAux.items[i].name === name ? navigationAux.items[i] : null;
            }
        }
        setNavigation({ ...navigation, 'items': navigationAux.items });
    }
    const _renderModal = () => {
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={closeModal}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:items-start">
                                        <div className="my-3 text-center sm:mt-0 sm:text-left" align="center">
                                            <Dialog.Title as="h3" align="center">
                                                <div>
                                                    <div align="right">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={closeModal}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                    <div className="text-2xl leading-6 font-semibold text-gray-900 pt-10">
                                                        <div>Datos Adicionales del proyecto</div>
                                                    </div>
                                                </div>
                                            </Dialog.Title>
                                            <div className="w-full mt-6">
                                                <input type="number" value={projectData.amountToOperate} onChange={handleDateProject} name="amountToOperate" id="amountToOperate" placeholder="Monto a operar" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline" />
                                                <input type="number" value={projectData.numWorkers} onChange={handleDateProject} name="numWorkers" id="numWorkers" placeholder="Número de trabajadores" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline" />
                                                <input type="number" value={projectData.hoursPerWorker} onChange={handleDateProject} name="hoursPerWorker" id="hoursPerWorker" placeholder="Horas por trabajador" className="my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline" />
                                                <div className="relative flex">
                                                    <DatePicker
                                                        className='my-2 text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        selected={projectData.startDate}
                                                        onChange={(date) => setProjectData({ ...projectData, startDate: date })}
                                                        locale="es"
                                                        dateFormat="dd/MM/yyyy"
                                                        closeOnScroll={true}
                                                        placeholderText="dd/M/yyyy"
                                                        nextMonthButtonLabel=">"
                                                        previousMonthButtonLabel="<"
                                                    />
                                                    {
                                                        projectData.startDate &&
                                                        (
                                                            <button
                                                                className="relative -left-5 text-gray-500 focus:outline-none"
                                                                onClick={() => setProjectData({ ...projectData, startDate: null })}
                                                            >
                                                                X
                                                </button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 pb-3 mb-8" >
                                    <div className="w-full px-1" align="center">
                                        <button
                                            type="button"
                                            className="text-white bg-color-btn border rounded-lg py-2 text-sm px-5 py-3"
                                            onClick={() => {
                                                saveProjectData();
                                            }}>
                                            Guardar
                            </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
    const render_docs = () => {
        const items = [];
        if (projects.item.id && projects.item.id > 0) {
            let selectNav = 0;
            for (var i = 0; i <= navigation.items.length - 1; i++) {
                if (navigation.items[i].current) {
                    selectNav = navigation.items[i].id;
                }
            }
            if (selectNav === 1) {
                if (disabledBtn && !cargado) {
                    let disableAux = false;
                    if (!projects.item.initProjectValidation) {
                        if (projects.item.waitingForLegalArea) {
                            disableAux = true;
                        }
                        if (projects.item.documentStatus && projects.item.documentStatus.quote !== 'acepted') {
                            disableAux = true;
                        }
                        if (projects.item.documentStatus && projects.item.documentStatus.contract !== 'acepted') {
                            disableAux = true;
                        }
                        if (projects.item.documentStatus && projects.item.documentStatus.signedContract !== 'acepted') {
                            disableAux = true;
                        }
                    }
                    setCargado(true);
                    setDisabledBtn(disableAux);
                }
                let quote = {
                    name: 'Cotización',
                    column: 'quote',
                    descripcion: 'Máx 50 MB: PDF / PNG / JPG',
                    url: projects.item.quoteFileUrl,
                    campo: 'quoteFileUrl',
                    btn: false,
                    state: projects.item.documentStatus ? projects.item.documentStatus.quote : null,
                    projectId: parseInt(id),
                    extra: {
                        documentStatus: {
                            quote: 'uploaded'
                        }
                    },
                    redirect: 'quote',
                    reject: projects.item.documentStatus ? projects.item.documentStatus.rejectionReasonQuote : null,
                    rejectValidation: projects.item.documentStatus ? projects.item.documentStatus.quote : null,
                }
                let contract = {
                    name: 'Contrato',
                    column: 'contract',
                    descripcion: 'Prestación de servicios',
                    url: projects.item.contractFileUrl,
                    campo: 'contractFileUrl',
                    btn: false,
                    state: projects.item.documentStatus ? projects.item.documentStatus.contract : null,
                    projectId: parseInt(id),
                    extra: {
                        documentStatus: {
                            contract: 'uploaded'
                        }
                    },
                    redirect: 'contract'
                }
                let contractSigned = {
                    name: 'Contrato firmado',
                    column: 'signedContract',
                    descripcion: 'Prestación de servicios',
                    url: projects.item.contractsignedfileurl,
                    campo: 'contractsignedfileurl',
                    btn: true,
                    state: projects.item.documentStatus ? projects.item.documentStatus.signedContract : null,
                    projectId: parseInt(id),
                    reject: projects.item.documentStatus ? projects.item.documentStatus.rejectionReasonSignedContract : null,
                    rejectValidation: projects.item.documentStatus ? projects.item.documentStatus.signedContract : null,
                }
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={quote} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Quote"} />)
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={contract} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Contract"} />)
                items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={contractSigned} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-Contract-Signed"} />)
            } else {
                if (projects.item.billingKindClient === 'moral') {
                    let quote = {
                        name: 'Acta constitutiva',
                        column: 'actaConstitutivaStatus',
                        descripcion: '',
                        url: projects.item.client.profile.actaConstitutivaFileUrl,
                        campo: 'actaConstitutivaFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.actaConstitutivaStatus ? projects.item.client.profile.documentStatus.actaConstitutivaStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let ifeRepLegalFileUrl = {
                        name: 'IFE/INE representante legal',
                        column: 'ifeRepLegalStatus',
                        descripcion: '',
                        url: projects.item.client.profile.ifeRepLegalFileUrl,
                        campo: 'ifeRepLegalFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.ifeRepLegalStatus ? projects.item.client.profile.documentStatus.ifeRepLegalStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let cifFileUrl = {
                        name: 'CIF',
                        column: 'cifStatus',
                        descripcion: '',
                        url: projects.item.client.profile.cifFileUrl,
                        campo: 'cifFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.cifFileStatus ? projects.item.client.profile.documentStatus.cifFileStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={quote} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-actaConstitutivaFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={ifeRepLegalFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-ifeRepLegalFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={cifFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-cifFileUrl"} />)
                } else if (projects.item.billingKindClient === 'fisica') {
                    let actaNacimientoFileUrl = {
                        name: 'Acta de nacimiento',
                        column: 'actaNacimientoStatus',
                        descripcion: '',
                        url: projects.item.client.profile.actaNacimientoFileUrl,
                        campo: 'actaNacimientoFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.actaNacimientoStatus ? projects.item.client.profile.documentStatus.actaNacimientoStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let comprobanteDomicilioFileUrl = {
                        name: 'Comprobante de domicilio',
                        column: 'comprobanteDomicilioStatus',
                        descripcion: '',
                        url: projects.item.client.profile.comprobanteDomicilioFileUrl,
                        campo: 'comprobanteDomicilioFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.comprobanteDomicilioStatus ? projects.item.client.profile.documentStatus.comprobanteDomicilioStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let ifePersonaFisicaFileUrl = {
                        name: 'IFE/INE persona fisica',
                        column: 'ifePersonaFisicaStatus',
                        descripcion: '',
                        url: projects.item.client.profile.ifePersonaFisicaFileUrl,
                        campo: 'ifePersonaFisicaFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.ifePersonaFisicaStatus ? projects.item.client.profile.documentStatus.ifePersonaFisicaStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let cifFileUrl = {
                        name: 'CIF',
                        column: 'cifStatus',
                        descripcion: '',
                        url: projects.item.client.profile.cifFileUrl,
                        campo: 'cifFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.cifFileStatus ? projects.item.client.profile.documentStatus.cifFileStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    let curpUrl = {
                        name: 'CURP',
                        column: 'curpStatus',
                        descripcion: '',
                        url: projects.item.client.profile.curpFileUrl,
                        campo: 'curpFileUrl',
                        btn: true,
                        state: projects.item.client.profile.documentStatus.curpStatus ? projects.item.client.profile.documentStatus.curpStatus : null,
                        projectId: parseInt(id),
                        cliente: projects.item.client.id,
                    }
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={actaNacimientoFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-actaNacimientoFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={ifePersonaFisicaFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-ifePersonaFisicaFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={comprobanteDomicilioFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-comprobanteDomicilioFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={cifFileUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-cifFileUrl"} />)
                    items.push(<CardDocumento updateStateSubmit={updateStateSubmit} item={curpUrl} notif={{ email: projects.item.client.email, fav: projects.item.favorite, title: projects.item.title }} key={"cardDoc_-curpFileUrl"} />)
                }
            }
        }
        return (items);
    }
    return (
        <div className="min-h-screen justify-start bg-white py-12 sm:px-6 lg:px-8">
            <div align="center">
                <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                    Documentos
                </h2>
            </div>
            <div align="center">
                <div className="rounded-2xl shadow-xl sm:w-5/12 sm:p-4 h-auto mt-10" align="left">
                    <div>
                        <h6 className="font-bold text-base">Informaci&oacute;n del proyecto</h6>
                    </div>
                    {render()}
                </div>
                <div className="container mt-8" >
                    <div className="sm:block">
                        <div className="w-1/6 space-x-12" align="center">
                            <div className="grid grid-cols-2">
                                {navigation.items.map((item) => (
                                    <h5
                                        key={item.name}
                                        name={item.name}
                                        className={classNames(
                                            item.current ? ' cursor-pointer border-b-4 border-color-select rounded-none' : 'cursor-pointer text-gray-500',
                                            'px-3 pt-2 rounded-md text-sm font-semibold flex items-center justify-center'
                                        )}
                                        onClick={changeNavigations}
                                    >
                                        {item.name}
                                    </h5>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:w-6/12 sm:p-4 h-auto mt-2">
                    <div className="grid grid-cols-2">
                        {render_docs()}
                    </div>
                </div>
                <div className="w-full" align="center">
                    {_renderModal()}
                </div>
                {
                    user.user.permissions.filter((permission) => permission.slug === 'switch_init_project')[0] && 
                        user.user.roleSlug === Constantes.ROLE_QUO &&
                            !projects.loading && (
                                <>
                                    <div className="sm:w-6/12 sm:p-4 h-auto mt-2 ml-8">
                                        <div className="flex items-start justify-start w-full mb-12">
                                            <label htmlFor="skipValidation" className="flex items-center cursor-pointer">
                                                <div className="relative">
                                                    <input type="checkbox" id="skipValidation" className="sr-only" checked={openInitValidation} onChange={() => {
                                                        // SetOpenSwitch(!openInitValidation);
                                                        setopenSwitch(!openSwitch);
                                                    }} />
                                                    <div className="block dot-back w-14 h-8 rounded-full"></div>
                                                    <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                                </div>
                                                <div className="ml-3 text-gray-700 font-medium text-left">
                                                    Habilitar iniciar proyecto sin documentación completa
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <InitValidation modalState={openSwitch} closeModal={setopenSwitch} switchProject={switchProject} result={result} />
                                </>
                            )
                }
                <div className="w-full" align="center">
                    {submit ? (
                        <div>
                            <Loader
                                type="ThreeDots"
                                color="#50afbd"
                                height={65}
                                width={50}
                                timeout={0}
                            />
                        </div>
                    ) : (
                        <>
                            {
                                
                                projects.item.initProjectValidation || (projects.item.documentStatus && projects.item.documentStatus.legalAreaApproval === true) ? (
                                    <input type="button" disabled={disabledBtn} onClick={openModal}
                                        className={
                                            classNames(!disabledBtn ? 
                                                "bg-color-btn cursor-pointer hover:shadow-none" : 
                                                "bg-gray-300", 'mx-2 p-2 shadow-xl rounded text-white'
                                            )
                                        }
                                        value="Iniciar proyecto"
                                    />
                                ) : (
                                    <>
                                        <input type="button" disabled={disabledBtn} onClick={() => setOpenCheck(!openCheck)}
                                            className={
                                                classNames(!disabledBtn ? 
                                                    "bg-color-btn cursor-pointer hover:shadow-none" : 
                                                    "bg-gray-300", 'mx-2 p-2 shadow-xl rounded text-white'
                                                )
                                            }
                                            value={ projects.item.waitingForLegalArea ? 'Documentación en revisión' : 'Enviar documentación a revisión' }
                                        />
                                        <CheckModal
                                            legalUsers={legalUsers}
                                            modalState={openCheck}
                                            closeModal={setOpenCheck}
                                            setDisabledBtn={setDisabledBtn}
                                        />
                                        
                                    </>
                                )
                            }
                        </>
                        
                    )}
                </div>
            </div>
        </div>
    )
}
export { Documentos };