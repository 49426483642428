import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SHOW:
            return{
                severity:action.severity,
                message:action.message,
            };
        case alertConstants.CLEAR:
            return{
                severity:null,
                message:null,
            };
        default:
            return state
    }
}
