import { recoverConstants } from '../_constants';
let item = {};
const initialState = { loading: true, item };

export function recover(state = initialState, action) {
    switch (action.type) {
        case recoverConstants.VALIDATE_RECOVER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case recoverConstants.VALIDATE_RECOVER_SUCCESS:
            return {
                ...state,
                loading:false,
                item: action.recover
            };
        case recoverConstants.VALIDATE_RECOVER_FAILURE:
            return {
                ...state,
                loading:false,
                error: action.error
            };
        case recoverConstants.RECOVER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case recoverConstants.RECOVER_SUCCESS:
            return {
                ...state,
                loading:false,
                item: action.recover
            };
        case recoverConstants.RECOVER_FAILURE:
            return {
                ...state,
                loading:false,
                error: action.error
            };
        default:
            return state
    }
}