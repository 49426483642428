import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { PROJECTS } from '../../Apollo/queries/projects';
import { useSelector } from 'react-redux';
import SearchFilter from './SearchFilter';
import { AssigmentModal } from './AssigmentModal';
import { USERS } from '../../Apollo/queries/users';
import { useQuery } from '@apollo/client';
import "react-datepicker/dist/react-datepicker.css";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Assignments(props) {
    const user = useSelector(state => state.authentication);
    const [open, setOpen] = useState(false);
    const [project, setProject] = useState({});
    const { data: dataExecutives } = useQuery(USERS, { variables: { filter: { roleId: parseInt(3) } } });
    const [getProjects, { data, loading }] = useLazyQuery(PROJECTS, {
        pollInterval: 1000
    });

    const [navigation, setNavigation] = useState({
        items: [
            { id: 1, name: 'No asignados', current: true },
            { id: 2, name: 'Asignados', current: false }
        ]
    });

    const [actualNav, setActualNav] = useState({
        id: 1,
        name: 'No asignados',
        current: true
    });

    useEffect(() => {
        changeProjects(actualNav.id);
    }, [data, actualNav]);

    useEffect(() => {
        user &&
            user.user &&
                user.user.roleSlug === 'executive' || user.user.roleSlug === 'legal' && props.history.push('/');
    }, [])

    const changeNavigations = (target) => {
        const name = target.getAttribute('name');
        let navigationAux = navigation;
        let nav = null;

        for (let i = 0; i <= navigationAux.items.length - 1; i++) {
            navigationAux.items[i].current =
                navigationAux.items[i].name === name ? true : false;
            if (nav === null) {
                nav =
                    navigationAux.items[i].name === name
                        ? navigationAux.items[i]
                        : null;
            }
        }

        setNavigation({ ...navigation, items: navigationAux.items });
        setActualNav(nav);
        changeProjects(nav.id);
    };

    const changeProjects = (id) => {
        const search = id === 1 ? false : true;

        getProjects({
            variables: {
                filter: {
                    assigned: search
                }
            }
        });
    }
    
    return (
        <>
            <div className="min-h-screen flex justify-start bg-white py-12 sm:px-6 lg:px-8">
                <div className="w-full px-28">
                    <div align="left">
                        <h2 className="mt-6 text-4xl font-bold font-poppins text-black">
                            Asignaciones
                        </h2>
                    </div>
                    <div className="container pt-10" align="left">
                        <div className="relative flex">
                            <div className="container">
                                <div className="sm:block ">
                                    <div className="w-full flex space-x-12">
                                        {navigation.items.map((item) => (
                                            <h5
                                                key={item.name}
                                                name={item.name}
                                                className={classNames(
                                                    item.current ? 'cursor-pointer border-b-4 border-color-select rounded-none' : 'cursor-pointer text-gray-500',
                                                    'px-3 pt-2 rounded-md text-sm font-semibold'
                                                )}
                                                onClick={(e) => changeNavigations(e.target)}>
                                                {item.name}
                                            </h5>
                                        ))}
                                    </div>
                                    {
                                        loading && (
                                            <div className="animate-pulse flex space-x-4 mx-10 mb-8">
                                                <div className="flex-1 space-y-4 py-1">
                                                    <div className="h-4 bg-gray-400 rounded w-full"></div>
                                                    <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                                                    <div className="space-y-2">
                                                        <div className="h-4 bg-gray-400 rounded"></div>
                                                        <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        data && <SearchFilter
                                            items={data.projects}
                                            user={user}
                                            executives={dataExecutives}
                                            setProject={setProject}
                                            setOpen={setOpen}
                                            open={open}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AssigmentModal project={project} navigation={navigation} setProject={setProject} open={open} setOpen={setOpen} />
        </>
    )
}
export { Assignments };