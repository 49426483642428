import { gql } from '@apollo/client';

export const SERVICES = gql`
    query services($filter: FilterService!){
        services(filter: $filter){
            id
            title
            businessId
        }
    }
`;

export const SUB_SERVICE = gql`
	query subservices($filter: FilterSubService!){
		subservices(filter: $filter){
			id
			title
			group
			serviceId
		}
	}
`;

