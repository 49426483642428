import React from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Layout } from '../Components/Layout';
import Home from '../Components/Home';
import { history } from '../_helpers';
import { Login } from '../_business/Login';
import {
    Proyectos,
    HistorialPagos,
    Documentos,
    Informacion,
    Assignments,
    ProyectosJuridico,
    DocumentosJuridico
} from '../_business/Proyects';
import { Recuperacion, NuevaContrasena } from "../_business/Recuperacion";
import { Register } from '../_business/Register';
import { Listado } from '../_business/Usuarios';
import { ToastProvider } from 'react-toast-notifications';
import { Worker } from '@react-pdf-viewer/core';

const PrivateRoutesConfig = ({ component: Component, ...rest }) => {
    const user = useSelector((state) => state.authentication);
    function isAuthenticated() {
        if (Object.keys('user').length > 0) {
            if (user.hasOwnProperty('user')) {
                return true;
            }
        } else {
            return false;
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated() === true) {
                    return (
                        <Layout>
                            <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'>
                                <Component {...props} />
                            </Worker>
                        </Layout>
                    );
                } else {
                    localStorage.setItem("redirectAction", props.location.pathname);
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const PublicRouteConfig = ({ component: Component, ...rest }) => (
    <ToastProvider autoDismiss={true} autoDismissTimeout={5000}>
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    </ToastProvider>
);

const mapStateToProps = (state) => {
    return {
        session: state.user,
    };
};

const PrivateRoute = connect(mapStateToProps)(PrivateRoutesConfig);
const PublicRoute = connect(mapStateToProps)(PublicRouteConfig);

const AppRouter = () => {
    const user = useSelector((state) => state.authentication);
    return (
        <BrowserRouter history={history}>
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                {
                    user && user.user && user.user.roleSlug === 'legal' ? (
                        <PrivateRoute exact path="/proyectos" component={ProyectosJuridico} />
                    ) : (
                        <PrivateRoute exact path="/proyectos" component={Proyectos} />
                    )
                }
                <PrivateRoute exact path="/asignaciones" component={Assignments} />
                <PublicRoute exact path="/login" component={Login} />
                <PublicRoute exact path="/recuperacion" component={Recuperacion} />
                <PrivateRoute exact path="/users" component={Listado} />
                <PrivateRoute 
                    exact 
                    path="/pagos/:id" 
                    component={HistorialPagos} 
                    />
                <PublicRoute
                    exact
                    path="/auth/set-password/:hash"
                    component={NuevaContrasena}
                />
                <PublicRoute
                    exact
                    path="/auth/register/:hash"
                    component={Register}
                />
                <PrivateRoute
                    exact
                    path="/informacion/:id"
                    component={Informacion}
                />
                {
                    user && user.user && user.user.roleSlug === 'legal' ? (
                        <PrivateRoute 
                            exact 
                            path="/documentos/:id" 
                            component={DocumentosJuridico} 
                        />
                    ) : (
                        <PrivateRoute 
                            exact 
                            path="/documentos/:id" 
                            component={Documentos} 
                        />
                    )
                }
                <PrivateRoute 
                    exact 
                    path="/documentos/:id" 
                    component={Documentos} 
                />
                {/* <Redirect from="*" to="/" /> */}
            </Switch>
        </BrowserRouter> 
    );
};

export default connect(mapStateToProps)(AppRouter);
