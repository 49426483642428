import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import fetch from 'node-fetch';
import { createUploadLink } from "apollo-upload-client";
require('dotenv').config({ path: require('find-config')('.env') })

const httpLink = createUploadLink({
    // uri: 'https://devgateway.quocode.com/gql',
    // uri: 'http://localhost:4001/gql',
    uri: process.env.REACT_APP_GQL_URI,
    fetch,
    credentials: 'same-origin'
});

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return {
            headers: {
                ...headers,
            },
        };
    } else {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        };
    }

});
const apollo = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink)
});

export default apollo;
