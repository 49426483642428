import { notificationService, userService } from '../_service';
export const notificationsActions = {
    sendNotificationOperator,
    sendNotificationClient,
    sendNotificationOperatorWithoutFav
};

function sendNotificationOperator(dataRequest, fav) {
    return dispatch => {
        if(fav && fav>0){
            userService.get(fav).then(
                response => {
                    if(response.id && response.id>0){
                        dataRequest.email = response.email;
                        notificationService.sendNotificationOperator(dataRequest).then(
                            notif => {
                                dispatch(success(notif));
                            }
                        );
                    }
                }
            );
        }
    };
    function success(notif) { return { type: "SEND_NOTIFICATION", notif } }
}

function sendNotificationClient(dataRequest) {
    return dispatch => {
        notificationService.sendNotificationClient(dataRequest).then(
            notif => {
                dispatch(success(notif));
            }
        );
    };
    function success(notif) { return { type: "SEND_NOTIFICATION", notif } }
}

function sendNotificationOperatorWithoutFav(dataRequest) {

    return dispatch => {
        notificationService.sendNotificationOperator(dataRequest).then(
            notif => {
                dispatch(success(notif));
            }
        );
    };

    function success(notif) { return { type: "SEND_NOTIFICATION", notif } }
}