import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../_actions";
import { SERVICES, SUB_SERVICE } from '../../Apollo/queries/services';
import { CardAssigment } from "./CardAssigment";
import { CardProyects } from "./CardProyects";
import { useQuery, useLazyQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import NoSearch from "../../_helpers/svgs/no_resultados.svg";
import { filter } from 'lodash';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SearchProject = ({ items, user, executives, setProject = () => {}, setOpen = () => {}, open = false, legal = false }) => {

    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const [search, setSearch] = useState({
        service: 0,
        subService: 0,
        razon: '',
        ejecutivo: '',
        nombreEjecutivo: ''
    });

    const [filterUser, setFilterUser] = useState('');

    const [subservice, setSubService] = React.useState(false);

    let services = [];

    const { data: dataServices } = useQuery(SERVICES, {
        variables: {
            filter: {
                businessId: 1,
            },
        },
    });

    if (dataServices) {
        services = dataServices.services;
    }

    const [getSubService] = useLazyQuery(
        SUB_SERVICE,
        {
            onCompleted(data) {
                setSubService(data.subservices);
            },
        }
    );

    const retrieveSubServices = async (serviceType) => {
        getSubService({
            variables: {
                filter: {
                    serviceId: Number(serviceType),
                },
            },
        });
    };

    useEffect(() => {
        dispatch(userActions.getAll());
    },[])

    useEffect(() => {
        if(search.service === 1) retrieveSubServices(1)
    }, [search.service]);

    const filterUserArray = useMemo(() => {
        if(filterUser === '') return users && users.items;

        return users && !users.loading && users.items &&
            users.items.filter((item) => item.displayName.toLowerCase().includes(filterUser.toLowerCase()))
    }, [filterUser, users]);

    const projects = useMemo(() => {
        let arrayAux = [];
        let filters = {}

        if(search.service !== 0 ) filters['serviceId'] = parseInt(search.service);
        if(search.subService !== 0) filters['subserviceId'] = parseInt(search.subService);
        if(search.ejecutivo !== '')filters['assignedTo']=parseInt(search.ejecutivo);

        let resp = filter(items, filters);

        if(resp.length > 0 && search.razon !== ''){
            arrayAux = resp.filter((item) => item && item.client && item.client.profile && item.client.profile.clientFilter.toLowerCase().includes(search.razon.toLowerCase()));
        } else {
            arrayAux = resp;
        }

        return arrayAux;
    }, [search, items]);

    return (
        <>
            <div className='mt-5 flex flex-col sm:flex-row'>
                <select
                    className="p-4 text-sm px-4 shadow bg-white md:w-1/4 w-full border rounded py-4 my-2 mx-2 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline"
                    id="serviceId"
                    name="serviceId"
                    onChange={(e) => {
                        if(e.target.value !== 1) {
                            setSearch( {...search, service: parseInt(e.target.value), subService: 0 });
                        } else {
                            setSearch( {...search, service: parseInt(e.target.value) });
                        }
                    }}
                    value={search.service}
                >
                    <option value='0'>
                        Categoria
                    </option>

                    {services.map((obj, id) => (
                        <option
                            key={`service_${obj.id}`}
                            value={obj.id}
                        >
                            {obj.title}
                        </option>
                    ))}
                </select>
                {
                    search.service === 1 && (
                        <select
                            className="text-sm px-4 shadow bg-white md:w-1/4 w-full border rounded py-4 my-2 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline"
                            id="subserviceId"
                            name="subserviceId"
                            onChange={(e) => setSearch({...search, subService: parseInt(e.target.value) })}
                            value={search.subService}
                        >
                        <option value='0'>
                            Sub categoria
                        </option>
                            {subservice &&
                                subservice.map(
                                (obj, id) => (
                                    <option
                                    key={`Subservice_${obj.id}`}
                                    value={obj.id}
                                    >
                                    {obj.title}
                                    </option>
                                )
                                )
                            }
                        </select>
                    )
                }
                <input
                    type='text'
                    className="p-4 text-sm px-4 shadow bg-white md:w-1/4 w-full border rounded py-4 my-2 mx-2 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline"
                    id="razonAlias"
                    name="razonalias"
                    placeholder="Razón social o Alias"
                    onChange={(e) => {
                        setSearch( {...search, razon: e.target.value });
                    }}
                    value={search.razon}
                />
                {
                    user && user.user && (user.user.roleSlug === 'admin' || user.user.roleSlug === 'quo') && (
                            <Menu as="div" className="relative inline-block text-left w-full text-sm shadow bg-white md:w-1/4 w-full border rounded my-2 mx-2 text-gray-500 leading-relaxed focus:outline-none focus:shadow-outline">
                                <Menu.Button className="w-full p-4 cursor-pointer" >
                                    <input
                                        type='text'
                                        className="w-full bg-white cursor-pointer"
                                        id="ejecutivo"
                                        name="ejecutivo"
                                        disabled
                                        placeholder="Ejecutivo de cuenta"
                                        value={search.nombreEjecutivo}
                                    />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <input
                                                type='text'
                                                className={filterUser === "" ? "w-full p-4 border-black bg-white focus:outline-none text-gray-400" : "w-full p-4 border-black bg-gray-100 focus:outline-none"}
                                                id="userFilter"
                                                name="userFilter"
                                                placeholder="Buscar..."
                                                onChange={(e) => setFilterUser(e.target.value)}
                                                value={filterUser}
                                            />
                                        </div>
                                        <div className="py-1">
                                            {
                                                filterUserArray &&
                                                filterUserArray.map((item, index) => {
                                                    if (item.roleSlug === 'executive') {
                                                        return (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        type='button'
                                                                        onClick={(e) => {
                                                                            setSearch({ ...search, ejecutivo: parseInt(item.id), nombreEjecutivo: item.displayName });
                                                                            setFilterUser('');
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'flex flex-col px-4 py-2 text-sm cursor-default hover:bg-gray-300 cursor-pointer w-full'
                                                                        )}
                                                                    >
                                                                        { item.displayName }
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                filterUserArray &&
                                                filterUserArray.length === 0 && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                type='button'
                                                                disabled
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'flex flex-col px-4 py-2 text-sm cursor-default w-full'
                                                                )}
                                                            >
                                                                No se ha encontrado ejecutivos
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            }
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                    )
                }
                <input type="button" value="Borrar filtros"
                    className={(search.service !== 0 || search.subService !== 0 || search.razon !== '' || search.ejecutivo !== '' || search.nombreEjecutivo !== '')?"my-2 mx-2 p-4 border rounded-lg shadow cursor-pointer bg-color-btn text-white hover:shadow-none":"my-2 mx-2 p-4 border rounded-lg text-gray-300"}
                    onClick={() => setSearch({
                        service: 0, subService: 0, razon: '', ejecutivo: '', nombreEjecutivo: '' 
                    })}
                    disabled={(search.service !== 0 || search.subService !== 0 || search.razon !== '' || search.ejecutivo !== '' || search.nombreEjecutivo !== '')?false:true}/>
                {/* <button
                    className="my-2 mx-2 border rounded-lg shadow cursor-pointer bg-color-btn text-white hover:shadow-none px-4 py-4 border rounded text-gray-300"
                    // className="bg-primary px-2 py-2 my-2 md:mx-4 text-white text-sm font-bold border rounded-xl font-poppin"
                    disabled={search.service === 0 && search.subService === 0 && search.razon === '' }
                    onClick={() => setSearch({ service: 0, subService: 0, razon: '', ejecutivo: '', nombreEjecutivo: '' })}
                >
                    Limpiar filtros
                </button> */}
            </div>

            <div className="container w-full mt-12" align="left">
                {
                    projects &&
                        projects.length !== 0 ? (
                            <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                                {
                                    !legal ? (
                                        projects.map(
                                            (project, index) => (
                                                <CardAssigment
                                                    key={`Card_${index}`}
                                                    executives={executives}
                                                    setProject={setProject}
                                                    setOpen={setOpen}
                                                    open={open}
                                                    item={project}
                                                    userId={parseInt(user.user.id)} 
                                                    key={"card_-" + index} 
                                                />
                                            )
                                        )
                                    ) : (
                                        projects.map(
                                            (project, index) => (
                                                <CardProyects
                                                    item={project}
                                                    userId={parseInt(user.user.id)}
                                                    key={`Card_${index}`} 
                                                />
                                            )
                                        )
                                    )
                                }
                            </div>
                        ) : (
                            <div className="w-full" align="center">
                                <img
                                    className="w-full sm:w-2/6"
                                    src={NoSearch}
                                    alt='No Search'
                                />
                            </div>
                        )
                }
            </div>
        </>
    )
}

export default React.memo(SearchProject);