import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../_actions";
import { Constantes } from "../../_helpers";
import validator from 'validator';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useToasts } from 'react-toast-notifications';

function Register(props) {
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    let { hash } = useParams();
    const [inputs, setInputs] = useState({
        nombre: '',
        apellidos: '',
        correo: '',
        contrasena: '',
        confirmacion: ''
    });
    const [submit, setSubmit] = useState(false);
    const [inputsError, setInputsError] = useState({
        items: {
            nombre: '',
            errorNombre: false,
            apellidos: '',
            errorApe: false,
            correo: '',
            errorCorreo: false,
            contrasena: '',
            errorContrasena: false,
            confirmacion: '',
            errorConf: false,
        }
    });
    // const user = useSelector(state => state.users);
    const invite = useSelector(state => state.invite);
    const alert = useSelector(state => state.alert);
    const { history } = props;
    useEffect(() => {
        if(alert.message && alert.message!==""){
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    useEffect(() => {
        if (hash === null || hash === undefined) {
            history.push(`/login`);
        }
        dispatch(userActions.validateHashInvite(hash));
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    }
    const handleBlur = (e) => {
        const { name, value } = e.target;
        var itemsErrorAux = inputsError;
        if (name === 'correo') {
            let val = validator.isEmail(value);
            itemsErrorAux.items.errorCorreo = !val;
            itemsErrorAux.items.correo = val ? "" : "El correo no tiene un formato valido";
        } else if (name === 'contrasena') {
            let val = value.length >= 8;
            itemsErrorAux.items.errorContrasena = !val;
            itemsErrorAux.items.contrasena = val ? "" : "La contraseña debe tener almenos 8 digitos";
        } else if (name === 'confirmacion') {
            let val = value === inputs.confirmacion;
            itemsErrorAux.items.errorConf = !val;
            itemsErrorAux.items.confirmacion = val ? "" : "Las contraseñas no coinciden";
        }
        setInputsError({ ...inputsError, 'items': itemsErrorAux.items });
    }
    const valEmpty = () => {
        let error = false;
        var errorsAux = inputsError;
        errorsAux.items.errorNombre = inputs.nombre === '';
        error = error ? error : (errorsAux.items.errorNombre ? true : false);
        errorsAux.items.nombre = errorsAux.items.errorNombre ? "Nombre requerido" : '';
        errorsAux.items.errorApe = inputs.apellidos === '';
        error = error ? error : (errorsAux.items.errorApe ? true : false);
        errorsAux.items.apellidos = errorsAux.items.errorApe ? "Apellidos requeridos" : '';
        errorsAux.items.errorCorreo = inputs.correo === '';
        error = error ? error : (errorsAux.items.errorCorreo ? true : false);
        errorsAux.items.correo = errorsAux.items.errorCorreo ? "Correo eléctronico requerido" : '';
        errorsAux.items.errorContrasena = inputs.contrasena === '';
        error = error ? error : (errorsAux.items.errorContrasena ? true : false);
        errorsAux.items.contrasena = errorsAux.items.errorContrasena ? "Contraseña requerida" : '';
        errorsAux.items.errorConf = inputs.confirmacion === '';
        error = error ? error : (errorsAux.items.errorConf ? true : false);
        errorsAux.items.confirmacion = errorsAux.items.errorConf ? "Confirmación requerido" : '';
        setInputsError({ ...inputsError, 'items': errorsAux.items });
        return error;
    }
    const handleSubmit = (e) => {
        const roleId = invite && invite.item && invite.item.roleId;

        if (!inputs.error && !inputsError.errorNombre && !inputsError.errorApe && !inputsError.errorCorreo && !inputsError.errorContrasena && !inputsError.errorConf) {
            if(!valEmpty()){
                const dataRequest = {
                    name: inputs.nombre,
                    lastname: inputs.apellidos,
                    email: inputs.correo,
                    password: inputs.contrasena,
                    roleId: roleId
                };
                dispatch(userActions.create(dataRequest, history));
            }
        }
        setTimeout(() => {
            setSubmit(false);
        }, 2000);
    }
    const _render = () => {
        if (invite.loading) {
            return (
                <div className="p-4 max-w-md w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-400 rounded w-full"></div>
                            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-400 rounded"></div>
                                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (invite.item.available) {
                return (
                    <div className="w-full">
                        <div className="min-h-screen flex flex-col text-center text-2xl font-light mt-10">
                            <h2 className="font-poppin text-2xl font-bold">
                                Regístrate
                            </h2>
                            <div className="flex justify-center text-left">
                                <div className="w-full max-w-xl">
                                    <div className="px-8 pt-6 my-4 mb-4">
                                        <div className="flex">
                                            <div className="flex-1">
                                                <div className="mb-4">
                                                    <label
                                                        className="block font-poppin text-xl text-label mb-2"
                                                        htmlFor="name"
                                                    >
                                                        Nombre
                                                    </label>
                                                    <input
                                                        className={
                                                            inputsError.items.errorNombre
                                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        }
                                                        id="nombre"
                                                        name="nombre"
                                                        type="text"
                                                        placeholder="Tú nombre"
                                                        value={inputs.nombre}
                                                        onChange={handleChange}
                                                    />
                                                    {inputsError.items.errorNombre ? (
                                                        <span className="text-red-700 text-xs">
                                                            {'*' +
                                                                inputsError.items.nombre}
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block font-poppin text-xl text-label mb-2"
                                                        htmlFor="email">
                                                        Correo electrónico
                                                    </label>
                                                    <input
                                                        className={
                                                            inputsError.items.errorCorreo
                                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        }
                                                        id="correo"
                                                        name="correo"
                                                        type="email"
                                                        placeholder="nombre@example.com"
                                                        value={inputs.correo}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {inputsError.items.errorCorreo ? (
                                                        <span className="text-red-700 text-xs">
                                                            {'*' +
                                                                inputsError.items.correo}
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block font-poppin text-xl text-label mb-2"
                                                        htmlFor="password"
                                                    >
                                                        Contraseña
                                                    </label>
                                                    <input
                                                        className={
                                                            inputsError.items.errorContrasena
                                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        }
                                                        id="contrasena"
                                                        name="contrasena"
                                                        type="password"
                                                        placeholder="mínimo 8 carateres"
                                                        value={inputs.contrasena}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {inputsError.items.errorContrasena ? (
                                                        <span className="text-red-700 text-xs">
                                                            {'*' +
                                                                inputsError.items.contrasena}
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block font-poppin text-xl text-label mb-2"
                                                        htmlFor="confirm"
                                                    >
                                                        Confirma tu contraseña
                                                    </label>
                                                    <input
                                                        className={
                                                            inputsError.items.errorConf
                                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        }
                                                        id="confirmacion"
                                                        name="confirmacion"
                                                        type="password"
                                                        placeholder="mínimo 8 carateres"
                                                        value={inputs.confirmacion}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {
                                                        inputsError.items.errorConf ? (
                                                            <span className="text-red-700 text-xs">
                                                                {'*' +
                                                                    inputsError.items.confirmacion}
                                                            </span>
                                                        ) : null}
                                                </div>
                                            </div>

                                            <div className="flex-1">
                                                <div className="mb-4 pl-5">
                                                    <label
                                                        className="block font-poppin text-xl text-label mb-2"
                                                        htmlFor="lastname">
                                                        Apellidos
                                                    </label>
                                                    <input
                                                        className={
                                                            inputsError.items.errorApe
                                                                ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                                : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                                        }
                                                        id="apellidos"
                                                        name="apellidos"
                                                        type="text"
                                                        placeholder="Tús apellidos"
                                                        value={inputs.apellidos}
                                                        onChange={handleChange}
                                                    />
                                                    {inputsError.items.errorApe ? (
                                                        <span className="text-red-700 text-xs">
                                                            {'*' +
                                                                inputsError.items.apellidos}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center text-center">
                                            {submit ? (
                                                <div>
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#50afbd"
                                                    height={65}
                                                    width={50}
                                                    timeout={0}
                                                />
                                            </div>
                                            ):
                                                (<button
                                                    className="bg-color-btn shadow-lg hover:shadow-sm my-5 p-2 text-white text-sm font-bold border rounded-xl font-poppin cursor-pointer"
                                                    type="button"
                                                    onClick={() => {
                                                        setSubmit(true);
                                                        handleSubmit();
                                                    }}
                                                >
                                                    Continuar
                                                </button>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return(
                    <div className="min-h-screen h-full  text-center justify-center pt-40 font-light mt-10 align-middle" align="center">
                        <h1 className="font-poppin  font-bold text-4xl">
                            Tu invitaci&oacute;n es invalida o ha caducado
                        </h1>
                        <h4 className="text-2xl">
                            Solicita una nueva invitación al administrado
                        </h4>
                    </div>
                )
            }
        }
    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
            {_render()}
        </div>
    )
}
export { Register };