import { gql } from '@apollo/client';

export const LEGAL_APPROVAL = gql`
    mutation($id: Int!, $input: UpdateProjectInput!) {
        updateProject(id: $id, input: $input){
            id
            assignedTo
        }
    }
`;

export const LEGAL_APPROVAL_QUESTION = gql`
    mutation($id: Int!, $input: UpdateProjectInput!) {
        updateProject(id: $id, input: $input){
            id
            assignedTo
        }
    }
`;
