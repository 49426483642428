import React, { useState, useEffect } from 'react';
import icon from "../../_helpers/svgs/office.svg";
import paperIcon from "../../_helpers/svgs/paper.svg";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function CardProyects(props) {
    const { item, onClickCard, userId, handleFavorite } = props;
    const user = useSelector((state) => state.authentication);
    const [dataDate, setDataDate] = useState({
        date: '',
        time: ''
    });

    useEffect(() => {
        if (item.createdAt) {
            const newDate = new Date(item.createdAt);
            setDataDate({
                date: newDate.toLocaleDateString('es-MX'),
                time: `${newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours() }:${newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()}`
            });
        }
    }, [item]);

    return (
        <div className="rounded-2xl shadow-lg sm:w-10/12 sm:p-4 h-auto flex flex-col justify-between" >
            <div className="grid grid-cols-3">
                <div>
                    <img
                        src={icon}
                        alt="icon" />
                </div>
                <div className="col-span-2" align="right">
                    {
                        user && user.user.roleSlug !== 'legal' && (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={()=>handleFavorite(item)}>
                                <path
                                    className={classNames(
                                        item.favorite===userId ? 'fill-current text-color-star' : ''
                                    )}
                                    strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                            </svg>
                        )
                    }
                </div>
            </div>
            <h5 className="font-bold font-normal py-2">{ `${item.id} - ${item.title !== null && item.title !== "" ? item.title : "Sin Nombre" }`}</h5>
            <h5 className="font-normal text-sm pb-2">{item.business}</h5>
            <h5 className="font-normal text-sm">{`${item.client.name} - ${item.client.lastname} ${dataDate.date} - ${dataDate.time}`}</h5>
            <div
                className={classNames(user && user.user.roleSlug !== 'legal' ? 'justify-between' : 'justify-start', 'flex items-center  pt-6')}
            >
                {
                    user && user.user.roleSlug !== 'legal' ? (
                        <>
                            <img
                                src={paperIcon}
                                alt="paperIcon" />
                            <div className="col-span-2 pt-2 cursor-pointer" align="right" onClick={()=>onClickCard(item)}>
                                <h5 className="text-color-btn font-bold">{item.section!=='en_proceso'?'Más información':'Ir al dashboard'}</h5>
                            </div>
                        </>
                    ) : (
                        <div className="col-span-2 pt-2 cursor-pointer">
                            <Link
                                to={`/documentos/${item.id}`}
                                className={
                                    classNames(
                                        item.waitingForLegalArea ? 'text-yellow-300' : 
                                            item.documentStatus.legalAreaApproval === null ? 'text-color-btn' : 
                                                item.documentStatus.legalAreaApproval ? 'text-green-600' : 'text-red-600', 'font-bold'
                                    )
                                }
                            >
                                {
                                    item.waitingForLegalArea ? 'Aprobación pendiente' : 
                                        item.documentStatus.legalAreaApproval === null ? 'Sin revisión' : 
                                            item.documentStatus.legalAreaApproval ? 'Documentación aprobada' : 'Documentación rechazada'
                                }
                            </Link>
                        </div>
                    )
                }

            </div>
        </div>
    )
}
export { CardProyects };