import { initializeApollo } from '../_helpers';
import gql from "graphql-tag";

export const notificationService = {
    sendNotificationOperator,
    sendNotificationClient
};
const client = initializeApollo(null);

function sendNotificationOperator(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            sendNotificationOperator(input:${parsedDataRequest})
        }`
    }).then(response => {
        return response.data.sendNotificationOperator;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}

function sendNotificationClient(dataRequest) {
    client.cache.reset();
    let parsedDataRequest = JSON.stringify(dataRequest).replace(/"([^"]+)":/g, '$1:');
    return client.mutate({
        mutation: gql`
        mutation{
            sendNotificationClient(input:${parsedDataRequest})
        }`
    }).then(response => {
        return response.data.sendNotificationClient;
    }).catch(function (e) {
        throw new Error(e.message);
    });
}