const Constantes = {
    // API_HOST: 'https://devgateway.quocode.com/gql',
    // API_HOST: 'http://localhost:4001/',
    // API_HOST: 'https://devgateway.quocode.com/gql',
    API_HOST: process.env.API_HOST,
    ROLE_ID_OPERATOR: 2,
    ROLE_ID_ADMIN: 1,
    ESTATUS_SOLICITUDES: 'solicitudes_cotizacion',
    ESTATUS_NO_INICIADO: 'no_iniciados',
    ESTATUS_EN_PROCESO: 'en_proceso',
    ESTATUS_TERMINADOS: 'terminados',
    ESTATUS_ADD_DOCUMENT:'quote_uploaded',
    JURIDICO_ALL: 'autorizado',
    JURIDICO_PENDIENTES: 'pendiente',
    ROLE_ADMIN: 'admin',
    ROLE_QUO: 'quo'
};
export {Constantes};
