import { gql } from '@apollo/client';

export const PROJECTS = gql`
    query projects($filter: FilterProject) {
        projects(filter: $filter) {
            id
            title
            description
            status
            createdAt
            businessId
            business
            serviceId
            service
            subserviceId
            subservice
            billingKindClient
            billingKindTarget
            clientId
            quoteFileUrl
            contractFileUrl
            contractsignedfileurl
            section
            documentStatus {
                quote
                contract
                signedContract
                rejectionReasonQuote
                rejectionReasonSignedContract
                legalAreaApproval
                rejectionReasonLegalArea
            }
            startDate
            amountToOperate,
            numWorkers
            hoursPerWorker
            fulfillment
            waitingForLegalArea
            initProjectValidation
            assignedTo
            assigned
            client {
                id
                name
                lastname
                displayName
                email
                avatar
                enabled
                verified
                roleId
                role
                profile {
                    celular
                    rfc
                    representanteLegal
                    razonSocial
                    domicilioSocial
                    domicilioFiscal
                    nombreCompleto
                    edad
                    curp
                    actaConstitutivaFileUrl
                    actaNacimientoFileUrl
                    ifeRepLegalFileUrl
                    ifePersonaFisicaFileUrl
                    comprobanteDomicilioFileUrl
                    cifFileUrl
                    curpFileUrl
                    alias
                    clientFilter
                    documentStatus {
                        actaConstitutivaStatus
                        actaNacimientoStatus
                        ifeRepLegalStatus
                        ifePersonaFisicaStatus
                        comprobanteDomicilioStatus
                        cifStatus
                        curpStatus
                    }
                }
            }
        }
    }
`;
