import { alertConstants } from '../_constants';

export const alertActions = {
    show,
    clear
};

function show(severity,message) {
    return {
        type: alertConstants.SHOW,
        severity,
        message
    };
}

function clear() {
    return {
        type: alertConstants.CLEAR
    };
}
