import { servicesConstants } from '../_constants';
let items = [];
let item = {};
const initialState = { loading: true, items, item };

export function services(state = initialState, action) {
	switch (action.type) {
		case servicesConstants.GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case servicesConstants.GET_SUCCESS:
			return {
				...state,
				loading:false,
				item: ((action.service!=null)?action.service:{})
			};
		case servicesConstants.GET_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		case servicesConstants.GETALL_REQUEST:
			return {
				...state,
				loading: true
			};
		case servicesConstants.GETALL_SUCCESS:
			return {
				...state,
				loading:false,
				items: ((action.services!=null)?action.services:[]),
			};
		case servicesConstants.GETALL_FAILURE:
			return {
				...state,
				loading:false,
				error: action.error
			};
		default:
			return state
	}
}