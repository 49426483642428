const userConstants = {
    CREATE_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_FAILURE: 'CREATE_USER_FAILURE',
    
    UPDATE_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_USER_FAILURE',

    GETALL_REQUEST: 'GETALL_USER_REQUEST',
    GETALL_SUCCESS: 'GETALL_USER_SUCCESS',
    GETALL_FAILURE: 'GETALL_USER_FAILURE',

    GET_REQUEST: 'GET_USER_REQUEST',
    GET_SUCCESS: 'GET_USER_SUCCESS',
    GET_FAILURE: 'GET_USER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGGED_IN',
    LOGGED_FAILURE: 'LOGGED_OUT',
};
export { userConstants };
