import React, { Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Menu, Transition } from '@headlessui/react';
import logo from "../_helpers/svgs/logo.svg";
import { Link } from 'react-router-dom';
import { userActions } from "../_actions";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Header(props) {
    const user = useSelector(state => state.authentication);
    const dispatch = useDispatch();
    const { history } = props
    const logout = () => {
        dispatch(userActions.logout(history));
    }

    return (
        <div className="flex items-center justify-between mt-12 w-full px-12 sm:px-36">
            <div>
                <img
                    src={logo}
                    width="200"
                    alt="logo" 
                    className="cursor-pointer"
                    onClick={()=>history.push(`/proyectos`)}/>
            </div>
            <div className="flex items-center" align="right">
                <div className="flex justify-center items-center w-full rounded-md px-4 py-2 bg-white font-bold text-4xl hover:bg-gray-50 hover:bg-gray-50 focus:outline-none">
                    <p className='text-color-btn flex px-4 py-2 text-sm cursor-default'>
                        { `${user.user.name} ${user.user.lastname}` }
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-color-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </div>
                <div className="flex flex-row-reverse">
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="inline-flex justify-center w-full text-color-btn rounded-md px-4 py-2 bg-white font-bold text-4xl hover:bg-gray-50 hover:bg-gray-50 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item disabled>
                                        {({ active }) => (
                                            <p
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex flex-col px-4 py-2 text-sm cursor-default'
                                                )}
                                            >
                                                <strong className='text-color-btn'>Usuario: </strong>
                                                { `${user.user.name} ${user.user.lastname}` }
                                            </p>
                                        )}
                                    </Menu.Item>
                                </div>
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                to="/proyectos"
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm'
                                                )}
                                            >
                                                Proyectos
                                            </Link>
                                        )}
                                    </Menu.Item>
                                    {
                                        (user.user.roleSlug === 'admin' || user.user.roleSlug === 'quo') && (
                                            <>        
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to="/users"
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'flex px-4 py-2 text-sm'
                                                                )}
                                                        >
                                                            Usuarios
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="/asignaciones"
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'flex px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Asignaciones
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type='button'
                                                onClick={logout}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm w-full text-color-btn'
                                                )}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                </svg>
                                                Cerrar sesión
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
}
export default Header ;