import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../_actions";
import { useToasts } from 'react-toast-notifications';
import Loader from 'react-loader-spinner';

function Recuperacion(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { history } = props;
    const alert = useSelector(state => state.alert);
    const [waiting, setwaiting] = useState(false);
    const [inputs, setInputs] = useState({
        email: ''
    });
    const [inputsError, setInputsError] = useState({
        items: {
            correo: '',
            errorCorreo: false
        }
    });
    useEffect(() => {
        if(alert.message && alert.message!==""){
            addToast(alert.message, { appearance: alert.severity });
        }
    }, [alert]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value })
    }
    const handleSubmit = () => {
        if (inputs.email !== "") {
            setwaiting(true);
            let itemsErrorAux = inputsError;
            itemsErrorAux.items.errorCorreo = false;
            itemsErrorAux.items.correo = "";
            setInputsError({ ...inputsError, 'items': itemsErrorAux.items });
            dispatch(userActions.recoverPassword(inputs.email, history));
        } else {
            let itemsErrorAux = inputsError;
            itemsErrorAux.items.errorCorreo = true;
            itemsErrorAux.items.correo = "Debe ingresar el correo eléctronico";
            setInputsError({ ...inputsError, 'items': itemsErrorAux.items });
        }
    }
    return (
        <div>
            <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
                <div className="border border-gray-300 rounded p-10 w-1/4">
                    <div align="center">
                        Recuperar contrase&ntilde;a
                    </div>
                    <div className="w-full">
                        <input type="email" name="email" value={inputs.email} onChange={handleChange} id="email" placeholder="Correo Eléctronico"
                             className={
                                inputsError.items.errorCorreo
                                    ? 'text-sm shadow appearence-none border border-red-700 rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline'
                                    : 'text-sm shadow appearence-none border rounded w-full py-2 px-3 text-gray-700 leading-relaxed focus:outline-none focus:shadow-outline my-4'
                            } />
                        {inputsError.items.errorCorreo ? (
                            <span className="text-red-700 text-xs">
                                {'*' +
                                    inputsError.items.correo}
                            </span>
                        ) : null}
                    </div>
                    <div align="center">
                    {waiting ? (
                                <div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#50afbd"
                                        height={65}
                                        width={50}
                                        timeout={0}
                                    />
                                </div>
                            ) : (
                                <input type="button" value="Recuperar" onClick={() => handleSubmit()} className="bg-color-btn text-white p-2 rounded cursor-pointer shadow-2xl hover:shadow-none" />
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Recuperacion }